import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Certificate } from '@app/_models/certificate';
import { ReportSearch } from '@app/_models/reportSearch';
import { RsaProvided } from '@app/_models/rsaProvided';
import { AlertService, AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { SearchService } from '@app/_services/search.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-rsa-service-provided',
  templateUrl: './rsa-service-provided.component.html',
  styleUrls: ['./rsa-service-provided.component.less']
})
export class RsaServiceProvidedComponent implements OnInit {
  certificateReportForm: FormGroup;
  rsaProvidedForm: FormGroup;
  submitted=false;
  loading= false;
  reportSearch = new ReportSearch();
  certificates : Certificate[];
  selectedCertificate: Certificate;
  currentCertificateIndex = 0;
  totalCertificate = 0;
  isCertificateExpired=false;
  showRsaForm: boolean;
  selectRsaProvide: RsaProvided;


  constructor(
    private formBuilder: FormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private certificateService:CertificateService,
    private router:Router,
    private accountService: AccountService,

  ) { }

  ngOnInit(): void {

    this.certificateReportForm = this.formBuilder.group({
      certificateNumber: [''],
      chasisNumber:[''],
      contactNumber:[''],
          });
    
    this.rsaProvidedForm = this.formBuilder.group({
      callerName:[''],
      callbackNumber:[''],
      city:[''],
      problemStatement:[''],
      resolutionComment:[''],
      rsaProvidedDate:[new Date()],
      rsaProvideInstanceNumber:[0]
  
    })

  }
  get f() { return this.certificateReportForm.controls; }
  get rsaF(){return this.rsaProvidedForm.controls;}

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    this.reportSearch = new ReportSearch();

    this.reportSearch.certificateNumber = this.certificateReportForm.get('certificateNumber').value;
    this.reportSearch.chasisNumber = this.certificateReportForm.get('chasisNumber').value;

  this.reportSearch.contactNumber = this.certificateReportForm.get('contactNumber').value;
  if (this.reportSearch.certificateNumber || this.reportSearch.chasisNumber || this.reportSearch.contactNumber )
  {
    this.reportSearch.issueDateFrom = "2020-01-01";
    this.reportSearch.issueDateTo = new Date().toISOString();
    this.searchService.getRenewCertificateList(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {

        this.certificates = x;
        this.totalCertificate = this.certificates.length;
        if (this.certificates.length == 0)
        {
          this.alertService.error("No Records Found")
        }
        else
        {
          this.selectedCertificate = this.certificates[0]
          this.validCertificate();
        }
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    })
  }
  else
  {
    this.alertService.error('No Search Criteria provided')
    this.loading = false;
  }
}
previousAction()
{
  this.currentCertificateIndex = this.currentCertificateIndex - 1;
  this.selectedCertificate = this.certificates[this.currentCertificateIndex];
  this.validCertificate();
}
nextAction()
{
  console.log(this.currentCertificateIndex);
  this.currentCertificateIndex = this.currentCertificateIndex + 1;
  this.selectedCertificate = this.certificates[this.currentCertificateIndex];
  this.validCertificate();
}
pageSelected(i:number)
{
  this.currentCertificateIndex = i - 1;
  this.selectedCertificate = this.certificates[this.currentCertificateIndex];
  this.validCertificate();
}
validCertificate()
{
  this.isCertificateExpired = false;
  this.selectedCertificate.rsaProvidedCount = this.selectedCertificate.rsaProvidedCount? this.selectedCertificate.rsaProvidedCount : 0;
  this.selectedCertificate.rsaNumberOfServices = this.selectedCertificate.rsaNumberOfServices? this.selectedCertificate.rsaNumberOfServices : 999;
  this.selectedCertificate.startDate = new Date(this.selectedCertificate.startDate).toISOString().split('T')[0];
  this.selectedCertificate.endDate = new Date(this.selectedCertificate.endDate).toISOString().split('T')[0];
  this.selectedCertificate.rsaProvidedInfo = this.selectedCertificate.rsaProvidedInfo ? this.selectedCertificate.rsaProvidedInfo : [];

  if (new Date(this.selectedCertificate.endDate) < new Date())
  {
    this.isCertificateExpired = true;
  }
  this.isCertificateExpired = this.selectedCertificate.rsaNumberOfServices <=this.selectedCertificate.rsaProvidedCount?true:this.isCertificateExpired;
}

// printCertificate(certificate:Certificate){
//   this.certificateService.PrintCertificate = certificate;
//   this.alertService.clear();
//   if (certificate.policyType.startsWith("RSA (CAR)")) {
//     this.router.navigate(['../print/RSAPv'], { relativeTo: null });
//   } else {

//   if (certificate.policyType.startsWith("CPA + RSA + DOC")) {
//     this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
//   } else {
//     if (certificate.policyType.includes("RSA")) {
//       this.router.navigate(['../print/RSANew'], { relativeTo: null });
//     } else
//     if (certificate.policyType.includes("CPA + DOC") || (certificate.gstShow && certificate.gstShow == "N" && certificate.policyType.includes("PA")))
//     this.router.navigate(['../print/CpaDoc'], { relativeTo: null });
//     else
//     {
//     if (certificate.policyType.includes("FINANCE"))
//     this.router.navigate(['../print/Finance'], { relativeTo: null });
//     else
//       this.router.navigate(['../print/PaNew'], { relativeTo: null });
//     }
//   }
// }
// }

enterRSAClick()
{
  this.submitted = false;
  this.showRsaForm = true;
}
editRsaProvide(rsaProvide:RsaProvided){
  this.submitted = false;
  this.showRsaForm = true;
  this.rsaProvidedForm.patchValue(rsaProvide)
}
onRsaSubmit()
{
  this.submitted = true;
  this.alertService.clear();
  if (this.rsaProvidedForm.invalid) {
    return;
  }
  this.loading = true;
  this.selectRsaProvide = new RsaProvided(this.rsaProvidedForm.getRawValue());

  if (this.selectRsaProvide.rsaProvideInstanceNumber == 0)
  {
    this.selectRsaProvide.rsaProvidedDate = new Date();
    this.selectRsaProvide.rsaProvideInstanceNumber = this.selectedCertificate.rsaProvidedInfo.length + 1;
    this.selectedCertificate.rsaProvidedCount = this.selectRsaProvide.rsaProvideInstanceNumber;
  }
  else
  {
    this.selectedCertificate.rsaProvidedInfo = this.selectedCertificate.rsaProvidedInfo.filter(a => a.rsaProvideInstanceNumber != this.selectRsaProvide.rsaProvideInstanceNumber);    
  }
  this.selectedCertificate.rsaProvidedInfo.push(this.selectRsaProvide);
  this.certificateService.rsaProvideCertificate(this.selectedCertificate)
  .pipe(first())
  .subscribe({
    next: (x) => {

      
      this.certificates = this.certificates.filter(a=> a.certificateNumber != x.certificateNumber);
      this.certificates.push(x);
      this.certificates = this.certificates.sort((a,b) => a.issueDate > b.issueDate?-1:1);
      this.alertService.success("RSA Provided Updated successfully on certificate Number " + this.selectedCertificate.certificateNumber)
      this.loading = false;
      this.submitted = false;
      this.rsaProvidedForm.reset();
      this.showRsaForm = false;
    },
    error: error => {
      this.alertService.error(error)
      this.loading = false;
      this.submitted = false;
    }
  })

}
}
