<!-- src/app/plans/plans.component.html -->
<div class="container">
  <form [formGroup]="planForm" (ngSubmit)="onSubmit()">
    <!-- <form [formGroup]="planForm" (ngSubmit)="onSubmit()"> -->
      <div class="form-row">
          <div class="form-group col">
              <label for="planId">Plan</label>
              <input type="text" formControlName="planId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planId.errors }" />
                <div *ngIf="submitted && f.planId.errors" class="invalid-feedback">
                  <div *ngIf="f.planId.errors.required">Plan needs to be selected</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="planCPACoverAmount">CPA Cover Amount</label>
              <input type="number" formControlName="planCPACoverAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planCPACoverAmount.errors }" />
              <div *ngIf="submitted && f.planCPACoverAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planCPACoverAmount.errors.required">Plan CPA Cover Amount is required</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="planTotalAmount">Market Amount</label>
              <input type="number" formControlName="planTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planTotalAmount.errors }" />
              <div *ngIf="submitted && f.planTotalAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planTotalAmount.errors.required">Plan Total Amount is required</div>
              </div>
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col">
              <label for="planAmount">Before GST</label>
              <input type="number" formControlName="planAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planAmount.errors }" />
              <div *ngIf="submitted && f.planAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planAmount.errors.required">Plan Amount is required</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="planGstAmount">GST Amount</label>
              <input type="number" formControlName="planGstAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planGstAmount.errors }" />
              <div *ngIf="submitted && f.planGstAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planGstAmount.errors.required">Plan GST Amount is required</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="printCpaPolicy">Print CPA Policy</label>
              <select class="form-select" formControlName="printCpaPolicy" [ngClass]="{ 'is-invalid': submitted && f.printCpaPolicy.errors }" >
                  <option *ngFor="let cpaPolicyFlag of booleanArray" [ngValue]="cpaPolicyFlag">
                    {{ cpaPolicyFlag }}
                  </option>
                </select>
              <div *ngIf="submitted && f.printCpaPolicy.errors" class="invalid-feedback">
                  <div *ngIf="f.printCpaPolicy.errors.required">Print CPA Policy is required</div>
              </div>
          </div>
        </div>
        <div class="form-row">
           <div class="form-group col" >
              <label for="printRsaNumber">Print RSA Number</label>
              <select class="form-select" formControlName="printRsaNumber" [ngClass]="{ 'is-invalid': submitted && f.printRsaNumber.errors }" >
                  <option *ngFor="let printRsaNumber of booleanArray" [ngValue]="printRsaNumber">
                    {{ printRsaNumber }}
                  </option>
                </select>
              <div *ngIf="submitted && f.printRsaNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.printRsaNumber.errors.required">Print RSA Numberis required</div>
              </div>
          </div>
          <div class="form-group col" >
              <label for="rsaNumberOfServices">RSA Number of Services</label>
              <input type="number" formControlName="rsaNumberOfServices" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.rsaNumberOfServices.errors }" />
          </div>
          <div class="form-group col">
              <label for="numberOfYears">Number of Years</label>
              <input type="number" formControlName="numberOfYears" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.numberOfYears.errors }" />
          </div>

      </div>
      <div class="form-row">
          <div class="form-group col">
              <label for="planNetAmount">Product Cost</label>
              <input type="number" formControlName="planNetAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planNetAmount.errors }" />
              <div *ngIf="submitted && f.planNetAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planNetAmount.errors.required">Plan Net Amount is required</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="planMaximumAmount">Maximum Amount</label>
              <input type="number" formControlName="planMaximumAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planMaximumAmount.errors }" />
              <div *ngIf="submitted && f.planMaximumAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planMaximumAmount.errors.required">Plan Maximum Amount is required</div>
              </div>
          </div>
          <div class="form-group col">
              <label for="planMinimumAmount">Minimum Amount</label>
              <input type="number" formControlName="planMinimumAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planMinimumAmount.errors }" />
              <div *ngIf="submitted && f.planMinimumAmount.errors" class="invalid-feedback">
                  <div *ngIf="f.planMinimumAmount.errors.required">Plan Minimum Amount is required</div>
              </div>
          </div>
        </div>
        <div class="form-row">
             <div class="form-group col">
              <label for="gstShow">GST Show</label> <br>
              <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="gstShow1"  formControlName="gstShow" value="Y" [ngClass]="{ 'is-invalid': submitted && f.gstShow.errors }" />
              <label class="form-check-label" for="gstShow1">Yes</label> 
              </div>
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="gstShow2" formControlName="gstShow" value="N" [ngClass]="{ 'is-invalid': submitted && f.gstShow.errors }" />
              <label class="form-check-label" for="gstShow2">No</label>
          </div>
              <div *ngIf="submitted && f.gstShow.errors" class="invalid-feedback">
                  <div *ngIf="f.gstShow.errors.required">GST Show is required</div>
              </div>
          </div>
         <div class="form-group col">
              <label for="gstApplicable">GST Applicable</label><br>
              <div class="form-check form-check-inline" >
              <input class="form-check-input" type="radio" id="gstApplicable1"  formControlName="gstApplicable" value="Y" [ngClass]="{ 'is-invalid': submitted && f.gstApplicable.errors }" />
              <label class="form-check-label" for="gstApplicable1">Yes</label> 
              </div>
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="gstApplicable2" formControlName="gstApplicable" value="N" [ngClass]="{ 'is-invalid': submitted && f.gstApplicable.errors }" />
              <label class="form-check-label" for="gstApplicable2">No</label>
          </div>
              <div *ngIf="submitted && f.gstApplicable.errors" class="invalid-feedback">
                  <div *ngIf="f.gstApplicable.errors.required">GST Applicable is required</div>
              </div>
          </div>
          <div class="form-group col">
            <label for="applicableOnlyOnPolicyType">Policy Type Applicable On</label>
            <select class="form-select" formControlName="applicableOnlyOnPolicyType" [ngClass]="{ 'is-invalid': submitted && f.applicableOnlyOnPolicyType.errors }" >
                <option *ngFor="let applicableOnlyOnPolicyTyp of applicableOnlyOnPolicyTypes" [ngValue]="applicableOnlyOnPolicyTyp">
                  {{ applicableOnlyOnPolicyTyp}}
                </option>
              </select>
              <div *ngIf="submitted && f.applicableOnlyOnPolicyType.errors" class="invalid-feedback">
                <div *ngIf="f.applicableOnlyOnPolicyType.errors.required">Plan needs to be selected</div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="policyType">Policy Type</label>
          <select class="form-select" formControlName="policyType" [ngClass]="{ 'is-invalid': submitted && f.policyType.errors }" >
              <option *ngFor="let polType of printInstructions" [ngValue]="polType.policyType">
                {{ polType.policyType}}
                <!-- {{ applicableOnlyOnPolicyTyp}} -->
              </option>
            </select>
            <div *ngIf="submitted && f.applicableOnlyOnPolicyType.errors" class="invalid-feedback">
              <div *ngIf="f.applicableOnlyOnPolicyType.errors.required">Plan needs to be selected</div>
          </div>
        </div>
        <div class="form-group col">
            <label for="counterId">Counter Series</label>
            <select class="form-select" formControlName="counterId" [ngClass]="{ 'is-invalid': submitted && f.counterId.errors }" >
              <option *ngFor="let serMaint of seriesMaintain" [ngValue]="serMaint.key">
                {{ serMaint.heading}}
              </option>
            </select>
              <div *ngIf="submitted && f.counterId.errors" class="invalid-feedback">
                <div *ngIf="f.counterId.errors.required">Counter Id needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
          <label for="masterPolicyNumber">Master Policy Number</label>
          <select class="form-select" formControlName="masterPolicyNumber" [ngClass]="{ 'is-invalid': submitted && f.masterPolicyNumber.errors }" >
            <option *ngFor="let polMaint of policyMaintain" [ngValue]="polMaint.key">
              {{ polMaint.heading}}
            </option>
          </select>
          <div *ngIf="submitted && f.masterPolicyNumber.errors" class="invalid-feedback">
              <div *ngIf="f.masterPolicyNumber.errors.required">Policy Number needs to be selected</div>
          </div>
      </div>
      <div class="form-group col">
        <label for="certificatePrefix">Certificate Prefix</label>
        <input type="text" formControlName="certificatePrefix" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.certificatePrefix.errors }" />
          <div *ngIf="submitted && f.certificatePrefix.errors" class="invalid-feedback">
            <div *ngIf="f.certificatePrefix.errors.required">Certificate Prefix needs to be entered</div>
        </div>
    </div>
</div>
      <div class="form-group">
          <button [disabled]="loading || !planForm.valid" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Save
          </button> &nbsp;
          <a (click)="addNew()" class="btn btn-link" >Add New Plan</a>
      </div>
  </form>    <!-- Add other form controls with similar structure -->

    <!-- <button type="submit" [disabled]="!planForm.valid">Submit</button>
    <button type="button" (click)="addNew()">Add New Plan</button>
  </form> -->
</div>

<div class="plans-list">
  <table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 10%">Plan Id</th>
            <th style="width: 10%">Plan Amount</th>
            <th style="width: 10%">Plan GST Amount</th>
            <th style="width: 10%">Plan Net Amount</th>
            <th style="width: 10%">Plan Maximum Amount</th>
            <th style="width: 10%">Plan Minimum Amount</th>
            <th style="width: 10%">Print CPA Policy</th>
            <th style="width: 10%">Gst Applicable</th>
            <th style="width: 10%">Gst Show</th>
            <th style="width: 10%">Applicable On Policy Type</th>
            <th style="width: 10%">Print RSA Number</th>
            <th style="width: 10%">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let plan of plans">
            <td>{{plan.planId}}</td>
            <td>{{plan.planAmount}}</td>
            <td>{{plan.planGstAmount}}</td>
            <td>{{plan.planNetAmount}}</td>
            <td>{{plan.planMaximumAmount}}</td>
            <td>{{plan.planMinimumAmount?plan.planMinimumAmount: 0}}</td>
            <td>{{plan.printCpaPolicy?plan.printCpaPolicy: "Yes"}}</td>
            <td>{{plan.gstApplicable? plan.gstApplicable == "Y"?"Yes":"No" :"Yes" }}</td>
            <td>{{plan.gstShow? plan.gstShow == "Y"?"Yes":"No" :"Yes" }}</td>
            <td>{{plan.applicableOnlyOnPolicyType? plan.applicableOnlyOnPolicyType :"Both" }}</td>
            <td>{{plan.printRsaNumber?plan.printRsaNumber: "No"}}</td>
            <td style="white-space: nowrap">
                <button (click)="onEdit(plan)" class="btn btn-sm btn-info " >Edit
                    <!-- <span *ngIf="user.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!user.isDeleting">Delete</span> -->
                </button> 
            </td>
        </tr>
    </tbody>
</table>
</div>
