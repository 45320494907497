import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService, AlertService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-print-certificate',
  templateUrl: './print-certificate.component.html',
  styleUrls: ['./print-certificate.component.less']
})
export class PrintCertificateComponent implements OnInit {
  certificateReportForm: FormGroup;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  loading = false;
  gridReady = true;
  reportSearch = new ReportSearch();
  issueDateFromSearch: string;
  issueDateToSearch: string;
  user: User;
  startDate: string;
  certificates: Certificate[];
  currentDate = new Date();
  emptyCertificate = new Certificate();
  renewCertificate = false;

  constructor(
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private certificateService: CertificateService,
    private configDataService: ConfigDataService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.renewCertificate = this.router.url.includes('renewCertificate');
    this.issueDateFrom = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.issueDateTo = this.issueDateFrom;
    this.user = this.accountService.userValue;
    this.certificateReportForm = this.formBuilder.group({
      certificateNumber: [''],
      chasisNumber: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      contactNumber: [''],
      issueDateFrom: [this.issueDateFrom],
      issueDateTo: [this.issueDateFrom],
      dealerId: [''],
    });
    this.configDataService.getTodaysDate()
      .subscribe(x => {
        this.startDate = new Date(new Date(x).setHours(0, 0, 0, 0)).toISOString();
        this.currentDate = new Date(x);
        this.issueDateFrom = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.certificateReportForm.controls['issueDateFrom'].setValue(this.issueDateFrom);
        this.certificateReportForm.controls['issueDateTo'].setValue(this.issueDateFrom);
        this.reportSearch.certificateNumber = "";
        this.reportSearch.userId = this.user.id;
        this.issueDateTo = this.issueDateFrom;

        this.reportSearch.issueDateTo = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);
        this.reportSearch.policyNumber = "";
        if ((this.user.role != "admin") && (this.user.role != "employee") && (!this.renewCertificate)) {
          this.onSubmit();
        }
        else {
          this.loading = false;
        }
      })
  }
  get f() { return this.certificateReportForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (this.user.id == undefined) {
      this.alertService.error("User Credential Missing, Please login again and try");
      return
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;

    this.reportSearch.certificateNumber = this.certificateReportForm.get('certificateNumber').value;
    this.reportSearch.chasisNumber = this.certificateReportForm.get('chasisNumber').value;

    if (this.renewCertificate) {
      this.reportSearch.contactNumber = this.certificateReportForm.get('contactNumber').value;
      if (this.reportSearch.certificateNumber || this.reportSearch.chasisNumber || this.reportSearch.contactNumber) {
        this.reportSearch.issueDateFrom = "2020-01-01";
        this.reportSearch.issueDateTo = new Date().toISOString();
        this.searchService.getRenewCertificateList(this.reportSearch)
          .pipe(first())
          .subscribe({
            next: (x) => {
              this.certificates = x;
              // this.gridOptions.rowData = x;
              this.gridReady = true;
              this.loading = false;
            },
            error: error => {
              this.alertService.error(error)
              this.loading = false;
            }
          })
      }
      else {
        this.alertService.clear()
        this.alertService.error("Please enter certificate, chasis or contact number");
        return;
      }
    }
    else {
      this.reportSearch.firstName = this.certificateReportForm.get('firstName').value;
      this.reportSearch.lastName = this.certificateReportForm.get('lastName').value;
      this.reportSearch.userName = this.certificateReportForm.get('userName').value;
      this.issueDateFrom = this.certificateReportForm.get('issueDateFrom').value;
      this.reportSearch.issueDateFrom = this.issueDateFrom.year + '-' + ('0' + this.issueDateFrom.month).slice(-2) + '-' + ('0' + this.issueDateFrom.day).slice(-2);
      this.issueDateFrom = this.certificateReportForm.get('issueDateTo').value;
      this.reportSearch.issueDateTo = this.issueDateFrom.year + '-' + ('0' + this.issueDateFrom.month).slice(-2) + '-' + ('0' + this.issueDateFrom.day).slice(-2);

      this.searchService.getCertificateReport(this.reportSearch)
        .pipe(first())
        .subscribe({
          next: (x) => {
            this.certificates = x;
            // this.gridOptions.rowData = x;
            this.gridReady = true;
            this.loading = false;
          },
          error: error => {
            this.alertService.error(error)
            this.loading = false;
          }
        })
    }
  }

  onReset() {
    this.alertService.clear();
    window.location.reload();

  }
  printCertificate(certificate: Certificate) {
    this.certificateService.PrintCertificate = certificate;
    console.log("certificate: " + JSON.stringify(certificate));
    this.alertService.clear();

    switch (true)
    {
      case certificate.policyType.startsWith("RSA (CAR)") :
        this.router.navigate(['../print/RSAPv'], { relativeTo: null });
        break;
      case certificate.policyType.startsWith("CPA + RSA + DOC") :
        this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
        break;
      case certificate.policyType.toUpperCase().startsWith("RSA EV") :
        this.router.navigate(['../print/RSAEv'], { relativeTo: null });
        break;
      case certificate.policyType.includes("RSA"):
        this.router.navigate(['../print/RSANew'], { relativeTo: null });
        break;
      case certificate.policyType.includes("CPA + DOC") || (certificate.gstShow && certificate.gstShow == "N" && certificate.policyType.includes("PA")):
        this.router.navigate(['../print/CpaDoc'], { relativeTo: null });
        break;

      case certificate.policyType.includes("FINANCE"):
        this.router.navigate(['../print/Finance'], { relativeTo: null });
        break;
      default:
        this.router.navigate(['../print/PaNew'], { relativeTo: null });
        break;

    }

    // if (certificate.policyType.startsWith("RSA (CAR)")) {
    //   this.router.navigate(['../print/RSAPv'], { relativeTo: null });
    // } else {

    //   if (certificate.policyType.startsWith("CPA + RSA + DOC")) {
    //     this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
    //   } else if (certificate.policyType.toUpperCase().startsWith("RSA EV")) {
    //     this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
    //   }
    //   else {
    //     {
    //       if (certificate.policyType.includes("RSA")) {
    //         this.router.navigate(['../print/RSANew'], { relativeTo: null });
    //       } else
    //         if (certificate.policyType.includes("CPA + DOC") || (certificate.gstShow && certificate.gstShow == "N" && certificate.policyType.includes("PA")))
    //           this.router.navigate(['../print/CpaDoc'], { relativeTo: null });
    //         else {
    //           if (certificate.policyType.includes("FINANCE"))
    //             this.router.navigate(['../print/Finance'], { relativeTo: null });
    //           else
    //             this.router.navigate(['../print/PaNew'], { relativeTo: null });
    //         }
    //     }
    //   }
    // }
  }
  cancelCertificate(certificate: Certificate) {
    certificate.isDeleting = true;
    this.certificateService.cancelCertificate(certificate).pipe(first())
      .subscribe({
        next: (x) => {
          this.certificates.map(x => { if (x.id == certificate.id) x.certificateStatus = "Cancelled"; certificate.isDeleting = false; })
        },
        error: error => {
          this.alertService.error(error);
          certificate.isDeleting = false;
        }
      })
  }
  endorseCertificate(certificate: Certificate) {
    this.certificateService.PrintCertificate = certificate;
    this.router.navigate(['../issueCertificate/editCertificate'], { relativeTo: null });
  }

  renewCertificateAction(certificate: Certificate) {
    this.certificateService.PrintCertificate = certificate;
    this.router.navigate(['../issueCertificate/renewCertificate'], { relativeTo: null });
  }

}
