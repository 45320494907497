import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportSearch } from '@app/_models/reportSearch';
import { User } from '@app/_models/user';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { ChartsService } from '@app/_services/charts.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import * as Highcharts from 'highcharts';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-number-of-policies',
  templateUrl: './number-of-policies.component.html',
  styleUrls: ['./number-of-policies.component.less']
})
export class NumberOfPoliciesComponent implements OnInit {
  highcharts: typeof Highcharts = Highcharts;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  loading= false;
  reportCreated = false;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  user: User;
  certificateReportForm: FormGroup;
  chartData: any[];
  populateChartData: any[];
  seriesData: any[];
  dateMap : Map<string,number>;
  initialDateMap = new Map<any,any>();
  searchRegex : RegExp;
  barChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 700
    },
    title: {
      text: 'Total Number of Policies by Type'
    },

  };
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      height: 700
    },
    title: {
      text: 'Total Number of Policy by Dealer'
    },

  //   // plotOptions: {
  //   //   series: {
  //   //       pointStart: Date.UTC(2010, 0, 1),
  //   //       pointInterval: 24 * 3600 * 1000 // one day
  //   //   }
  // },
    series: []
    //   {
    //     name: 'Normal',
    //     turboThreshold: 500000,
    //     data: [[new Date('2018-01-25 18:38:31').getTime(), 2]]
    //   },
    //   {
    //     name: 'Abnormal',
    //     turboThreshold: 500000,
    //     data: [[new Date('2018-02-05 18:38:31').getTime(), 7]]
    //   }
    // ]
  }
  

  constructor(        
    private formBuilder: FormBuilder,
    private chartsService: ChartsService,
    private alertService: AlertService,
    private accountService: AccountService
    ) { }

  ngOnInit()  {
    this.dateMap = new Map();
    this.user = this.accountService.userValue;
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      dealerName: [''],
          });
    
    this.certificateReportForm.get('dealerName').valueChanges
    .subscribe(value => {
      if ((value != undefined) && (this.chartData)) {
        this.searchRegex = new RegExp(value,"i");
        this.populateChartData =  this.chartData.filter(x => x.dealerName.match(this.searchRegex) )
        
        this.plotLineArray(this.populateChartData);
        this.plotBarArray(this.populateChartData);
        this.plotTotalLineArray(this.populateChartData);

      }
    });

    this.certificateReportForm.get('dealerId').valueChanges
    .subscribe(value => {
      if ((value != undefined) && (this.chartData)) {
        this.searchRegex = new RegExp(value,"i");
        this.populateChartData =  this.chartData.filter(x => x.dealerId.match(this.searchRegex) )
        
        this.plotLineArray(this.populateChartData);
        this.plotBarArray(this.populateChartData);
        this.plotTotalLineArray(this.populateChartData);

      }
    });

    this.certificateReportForm.get('issueDateFrom').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
    });

    this.certificateReportForm.get('issueDateTo').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
      console.log("issueDateToSearch: " + this.issueDateToSearch)
    });

  }
      // convenience getter for easy access to form fields
      get f() { return this.certificateReportForm.controls; }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();

    if (this.user.role != "admin" && this.user.role != "dealerOwner")
    {
      this.alertService.error("Unauthorized Access");
      return
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined)
    {
      this.reportSearch.issueDateFrom = "0001-01-01";
    }
    else
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined)
    {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
    this.reportSearch.issueDateTo = this.issueDateToSearch;


    this.chartsService.getSummedupData(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.chartsService.setChartData(x);
        this.chartData = x;
        this.plotLineArray(x);
        this.plotBarArray(x);
        this.plotTotalLineArray(x);
        // console.log(JSON.stringify(this.chartData));
        this.loading = false;
        this.reportCreated = true;
    
          },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    })      

  }
  plotLineArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];


    chartData.sort((a,b) => {if (a.dealerName + a.createdDateString > b.dealerName + b.createdDateString) return 1;
    else return -1;}).map(elementData=>{
      if (elementData.dealerName != DealerName){
        if (DealerName != "")
        {
          var count = 0;
          this.seriesData.push(
            {
              name: DealerName,
              data: Array.from(Object.entries(this.dateMap).map(([key,value]) => (value)))
              .map((x) => count = count + x),
            }
          );
        }
        for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
        { 
          var charDate = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
          ].join('-');
          this.dateMap[charDate] = 0;
         };
        DealerName = elementData.dealerName;
        this.dateMap[elementData.createdDateString] = elementData.numberOfPolicySold;
      }
      else {
        this.dateMap[elementData.createdDateString] = elementData.numberOfPolicySold;
      }

    });
    var count = 0;
    this.seriesData.push(
      {
        name: DealerName,
        data: Array.from(Object.entries(this.dateMap).map(([key,value]) => (value)))
         .map((x) => count = count + x),
      });

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.chartOptions.xAxis = {
      categories: Array.from(Object.entries(this.dateMap).map(([key,value]) => (key))).sort((a, b) => a > b?1:-1) 
    };
    if (this.user.role == "admin")
    this.chartOptions.title.text = "Total Policies By Dealer";
    else
    this.chartOptions.title.text = "Total Policies By Executive";

    this.chartOptions.series = this.seriesData;
    // this.chartOptions.plotOptions.series.pointStart = Date.UTC(parseInt(this.reportSearch.issueDateFrom.slice(1,4)),parseInt(this.reportSearch.issueDateFrom.slice(6,7)),parseInt(this.reportSearch.issueDateFrom.slice(9,10)));
    // console.log(JSON.stringify(this.chartOptions));
    // if (this.user.role =="admin")
    Highcharts.chart('lineChart',this.chartOptions);
    // this.highcharts.chart(this.chartOptions);
  }

  plotBarArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];
    var cpaDateMap = new Map();
    var rsaDateMap = new Map();
    var docDateMap = new Map();
    for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
    { 
      var charDate = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
      cpaDateMap[charDate] = 0;
      rsaDateMap[charDate] = 0;
      docDateMap[charDate] = 0;
     };


    chartData.map(elementData=>{
      cpaDateMap[elementData.createdDateString] = cpaDateMap[elementData.createdDateString] + elementData.numberOfCpaPolicySold;
      rsaDateMap[elementData.createdDateString] = rsaDateMap[elementData.createdDateString] + elementData.numberOfRsaPolicySold;
      docDateMap[elementData.createdDateString] = docDateMap[elementData.createdDateString] + elementData.numberOfDocPolicySold;
 });
    this.seriesData.push(
      {
        name: "HA",
        data: Array.from(Object.entries(cpaDateMap).map(([key,value]) => (value))),
      }
      ,
      {
        name: "RSA",
        data: Array.from(Object.entries(rsaDateMap).map(([key,value]) => (value))),
      }
      ,
      {
        name: "Flexible",
        data: Array.from(Object.entries(docDateMap).map(([key,value]) => (value))),
      }
      );

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.barChartOptions.xAxis = {
      categories: Array.from(Object.entries(cpaDateMap).map(([key,value]) => (key))) 
    };
    this.barChartOptions.yAxis = {
      min:0,
      title: {
        text: 'Total Number Of Policies'
      },
      stackLabels: {
        enabled: true
      }
    };
    this.barChartOptions.plotOptions = {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled:true
        }
      }
    };
    this.barChartOptions.series = this.seriesData;
    this.barChartOptions.chart.type = "column"; 
    Highcharts.chart('barChart',this.barChartOptions);
  }

  plotTotalLineArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];
    var totalDateMap = new Map();
    for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
    { 
      var charDate = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
      totalDateMap[charDate] = 0;
     };


    chartData.map(elementData=>{
      totalDateMap[elementData.createdDateString] = totalDateMap[elementData.createdDateString] + elementData.numberOfCpaPolicySold + elementData.numberOfRsaPolicySold + elementData.numberOfDocPolicySold;
 });
  var count = 0;
    this.seriesData.push(
      {
        name: "Total Policies",
        data: Array.from(Object.entries(totalDateMap).map(([key,value]) => (value)))
         .map((x) => count = count + x),
      });

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.chartOptions.xAxis = {
      categories: Array.from(Object.entries(totalDateMap).map(([key,value]) => (key))).sort((a, b) => a > b?1:-1) 
    };
    this.chartOptions.title.text = "Total Policies";
    this.chartOptions.series = this.seriesData;
    // this.chartOptions.plotOptions.series.pointStart = Date.UTC(parseInt(this.reportSearch.issueDateFrom.slice(1,4)),parseInt(this.reportSearch.issueDateFrom.slice(6,7)),parseInt(this.reportSearch.issueDateFrom.slice(9,10)));
    // console.log(JSON.stringify(this.chartOptions));
    Highcharts.chart('totalLineChart',this.chartOptions);

  }
}
