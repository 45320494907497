import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MakeModel } from '@app/_models/makeModel';
import { PlanDetails } from '@app/_models/planDetails';
import { StateCity } from '@app/_models/stateCity';
import { DealerService } from '@app/_services/dealer-service';
import { AccountService, AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { first } from 'rxjs/internal/operators/first';
import { Dealer } from '@app/_models/dealer';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html',
})
export class AddDealerComponent implements OnInit {
  form: FormGroup;
  planForm:FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  dealerId: string;
  dealer: Dealer;
  plans: PlanDetails[];
  makes: MakeModel[];
  stateCity: StateCity[];
  selectedState: StateCity[];
  cities: string[];
  planTypes = [{"id":"FP","text":"Full Pay"}, {"id":"CP","text":"Cut and Pay"}, {"id":"NP","text":"Net Pay"}]
  dealerTypes = ["Dealer","Sub-Dealer"];
  updateDealerInfo: Dealer;
  dealerInvoiceShowValue = ["Yes", "No"];
  dealerPaymentShowValue = ["Yes", "No"];
  canCreatePackageValues = [true,false];


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    console.log("Id: " + this.id);
    this.isAddMode = !this.id;
    this.configDataService.StateCity$.subscribe((x) => {
      this.stateCity = x;
    });
    this.configDataService.PlanDetails$.subscribe((x) => {
      this.plans = x;
    });
    this.configDataService.MakeModel$.subscribe((x) => {
      this.makes = x;
    });
    this.form = this.formBuilder.group({
      dealerId: [this.dealerId, Validators.required],
      dealerName: ['', Validators.required],
      dealerAddress: ['', Validators.required],
      dealerState: ['', Validators.required],
      dealerCity: [''],
      dealerContactNumber: ['', Validators.required],
      dealerEmail: ['', [Validators.required,Validators.email]],
      dealerBalance: ['', Validators.required],
      dealerBalanceLimit: ['', Validators.required],
      dealerMake: ['', Validators.required],
      dealerPlanType: ['', Validators.required],
      dealerTotalPayment: [0],
      dealerTotalGstPayment: [0],
      dealerTotalRefund: [0],
      dealerTotalGstRefund: [0],
      dealerPendingRefund: [0],
      dealerGstPendingRefund: [0],
      dealerType: [''],
      dealerBankAccountOwner: [''],
      dealerBankAccountNumber: [''],
      dealerBankIfscCode: [''],
      dealerBankOwnerPan: [''],
      dealerGstId: [''],
      dealerVpaId: [''],  
      dealerGroupName: [''],  
      dealerInvoiceShow: ['Yes'], 
      dealerPaymentShow: ['No'],
      dealerGst: ['Full'],
      invoiceType:['Commission'],
      dealerCreatePackage:[this.canCreatePackageValues[1]], 
      onePager:[this.canCreatePackageValues[1]], 
      dealerPolicyBalance:[{value:0,disabled:true}],
      dealerPackageBalance:[{value:0,disabled:true}],
 
    });

    
    // this.form.controls['dealerId'].setValue(this.dealerId);

    // if (!this.isAddMode) {
    //   this.accountService.getById(this.id)
    //     .pipe(first())
    //     .subscribe(x => this.form.patchValue(x));
    // }
    if (this.isAddMode) {
      this.dealerService.getDealerId().subscribe((x) => {
        this.dealerId = "DLR-" + x;
        this.form.controls['dealerId'].setValue(this.dealerId);
        console.log("state Num: " + x)
      })

    }
    else {
      this.dealerService.getByDealerId(this.id)
      .pipe(first())
      .subscribe(x => {this.form.patchValue(x);
        this.dealerId = x.dealerId;
        this.dealer = x;
        console.log("dealerPlanType: " + JSON.stringify(x));
        this.form.get('dealerMake').patchValue(x.dealerMake);
        //this.form.get('dealerPlanType').patchValue(x.dealerPlanType);
        this.form.get('dealerPackageBalance').setValue(this.dealer.dealerBalance + (this.dealer.dealerPolicyBalance ? this.dealer.dealerPolicyBalance : 0));
        if (x.dealerInvoiceShow)
        {
          this.form.get('dealerInvoiceShow').patchValue(x.dealerInvoiceShow);
        }
        else
        {
          this.form.get('dealerInvoiceShow').patchValue("Yes");
        }
        if (x.dealerPaymentShow)
        {
          this.form.get('dealerPaymentShow').patchValue(x.dealerPaymentShow);
        }
        else
        {
          this.form.get('dealerPaymentShow').patchValue("No");
        }
        

      // this.form.controls['dealerMake'].setValue
      });

    }

    this.form.get('dealerState').valueChanges.subscribe((value) => {
     if(value != undefined){ 
      this.configDataService.StateCity$.subscribe(
        (x) => this.cities = x.find(a => a.state == value).city
      )
    }
  })
  }

  get f() { return this.form.controls; }
  
  onSubmit() {
    console.log(this.form.value);
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createDealer();
    }
    else {
        this.updateDealer();
    }

  }
  createDealer() {
    this.form.controls['dealerTotalPayment'].setValue(0);
    this.form.controls['dealerTotalGstPayment'].setValue(0);
    this.form.controls['dealerTotalRefund'].setValue(0);
    this.form.controls['dealerTotalGstRefund'].setValue(0);
    this.form.controls['dealerPendingRefund'].setValue(0);
    this.form.controls['dealerGstPendingRefund'].setValue(0);

    this.dealerService.addDealer(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Dealer added successfully', { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });

  }
  updateDealer(){
    this.updateDealerInfo = new Dealer(this.form.value);
    console.log("this.form.value: " + this.form.value);
    this.dealerService.update(this.dealer.id, this.form.value)
    .pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Update successful', { keepAfterRouteChange: true });
            this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });
}
makeCompare(m1:MakeModel,m2:MakeModel): boolean{
  return m1 && m2? m1.make == m2.make : m1 ===m2;
}
}
