import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { Invoice } from '@app/_models/invoice';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';
import { Payouts } from '../../_models/payout';

@Component({
  selector: 'app-dealer-payout',
  templateUrl: './dealer-payout.component.html',
  styleUrls: ['./dealer-payout.component.less']
})
export class DealerPayoutComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  id: string;
  payoutDetailForm: FormGroup;
  gridOptions: GridOptions;
  gridReady = false;
  numberofRecords = [10, 20, 50, 100, 200, 500]
  submitted = false;
  loading = false;
  isPayoutShow = false;
  isInvoiceDataShow = true;
  isInvoiceDetailDataShow=true;
  isCertificateReportShow=false;
  isInvoiceHistoryShow=false;
  payout: Payouts;
  payoutDetails: Payouts[];
  currentDate = new Date();
  user: User;
  dealer: Dealer;
  oldDealer:Dealer;
  invoice: Invoice;
  selectedInvoice : Invoice;
  invoices: any[];
  newYears:Number[]; 
  months = [{"name": "January","value":"01"},{"name": "February","value":"02"},{"name": "March","value":"03"},{"name": "April","value":"04"},{"name": "May","value":"05"},{"name": "June","value":"06"},{"name": "July","value":"07"},{"name": "August","value":"08"},{"name": "September","value":"09"},{"name": "October","value":"10"},{"name": "November","value":11},{"name": "December","value":12}]


  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private configDataService: ConfigDataService,
    private accountService: AccountService,
    private router: Router


  ) { }

  ngOnInit(): void {
    this.newYears =  [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1, this.currentDate.getFullYear() - 2];
    this.gridOptions = <GridOptions>{}; 
    this.user = this.accountService.userValue;

    if (this.user.role == "admin") {
      this.id = this.route.snapshot.params['id'];
    }
    else {
      this.id = this.user.dealerId;
    }
    this.gridOptions.columnDefs = [
      {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
      ,{headerName: "Payout Date", field: "payoutDate",sortable: true, filter:true }
      ,{headerName: "Payout Amount", field: "payoutAmount",sortable: true, filter:true }
      ,{headerName: "Payout GST Amount", field: "payoutGSTAmount",sortable: true, filter:true }
      ,{headerName: "Actual Paid Amount", field: "actualPayoutAmount",sortable: true, filter:true }
      ,{headerName: "Actual GST Paid Amount", field: "actualPayoutGSTAmount",sortable: true, filter:true }
      ,{headerName: "Comments", field: "comments",sortable: true, filter:true }
      ,{headerName: "Account Received", field: "receiverAccountNumber",sortable: true, filter:true }
      ,{headerName: "Pan Number", field: "receiverPanNumber",sortable: true, filter:true }
      ,{headerName: "GST Number", field: "receiverGstNumber",sortable: true, filter:true }
      ,{headerName: "Invoice Number", field: "invoiceNumber",sortable: true, filter:true }
      ,{headerName: "Before Tx Payout Amount", field: "totalPayoutAmountBeforeTx",sortable: true, filter:true }
      ,{headerName: "Before Tx Payout GST Amount", field: "totalGSTPayoutAmountBeforeTx",sortable: true, filter:true }
      ,{headerName: "Before Tx Pending Amount", field: "totalPendingAmountBeforeTx",sortable: true, filter:true }
      ,{headerName: "Before Tx Pending GST Amount", field: "totalGSTPendingAmountBeforeTx",sortable: true, filter:true }
      // ,{headerName: "Payout Username", field: "userName",sortable: true, filter:true }
    ];
    this.gridOptions.rowData = [];
    this.gridReady = true;

    this.payoutDetailForm = this.formBuilder.group({
      recordCount: [10, Validators.required],
      invoiceYear: [],
      invoiceMonth: [],
      invoiceNumber: [],
    });

    this.payoutDetailForm.get('invoiceNumber').valueChanges      
    .subscribe(value => {
      if (value != undefined) {
        this.selectedInvoice.invoiceNumber = value;
      }
    });
  }
  get pmntDtlForm() { return this.payoutDetailForm.controls; }
  get f() { return this.payoutDetailForm.controls; }

  ShowInvoiceDataToggle(){
    this.isInvoiceDataShow = !this.isInvoiceDataShow;
  }
  ShowInvoiceDetailDataToggle(){
    this.isInvoiceDetailDataShow = !this.isInvoiceDetailDataShow;
  }
  ShowInvoiceHistoryDataToggle(){
    if (!this.dealer){
      this.dealerService.getByDealerId(this.id)
      .pipe(first())
      .subscribe(x => {
        this.dealer = x;
        this.oldDealer = JSON.parse(JSON.stringify(x));
        this.invoices = Array.from(Object.entries(this.dealer.dealerInvoice).map(([key,value]) => (value)));      
        const InvoiceMapKey = this.payoutDetailForm.get('invoiceYear').value + "-" + this.payoutDetailForm.get('invoiceMonth').value + "-NotSubmitted";
        this.selectedInvoice = new Invoice(this.oldDealer.dealerInvoice[InvoiceMapKey]);
        this.isInvoiceHistoryShow = !this.isInvoiceHistoryShow;
        this.dealer.invoiceType = this.dealer.invoiceType ? this.dealer.invoiceType : "Commission";
      });
    }
    else
    {
    this.isInvoiceHistoryShow = !this.isInvoiceHistoryShow;
    }
  }
  payoutShowToggle(){
    this.isPayoutShow = !this.isPayoutShow;
  }

  onReset() {
    window.location.reload();
  }
  onSubmit(){
    if (!this.dealer){
    this.dealerService.getByDealerId(this.id)
    .pipe(first())
    .subscribe(x => {
      this.dealer = x;
      this.oldDealer = JSON.parse(JSON.stringify(x));
      this.invoices = Array.from(Object.entries(this.dealer.dealerInvoice).map(([key,value]) => (value)));      
      const InvoiceMapKey = this.payoutDetailForm.get('invoiceYear').value + "-" + this.payoutDetailForm.get('invoiceMonth').value + "-NotSubmitted";
      this.selectedInvoice = new Invoice(this.oldDealer.dealerInvoice[InvoiceMapKey]);
      this.dealer.invoiceType = this.dealer.invoiceType ? this.dealer.invoiceType : "Commission";
      if(!this.selectedInvoice.invoiceStatus)
      {
        this.alertService.info("No Invoice Found")
      }
      else 
      {
        this.payoutDetailForm.controls['invoiceNumber'].setValue(this.selectedInvoice.invoiceNumber);
        this.alertService.clear();
      }
      });
  }
  else
  {
    const InvoiceMapKey = this.payoutDetailForm.get('invoiceYear').value + "-" + this.payoutDetailForm.get('invoiceMonth').value + "-NotSubmitted";
    this.selectedInvoice = new Invoice(this.oldDealer.dealerInvoice[InvoiceMapKey]);
    if(!this.selectedInvoice.invoiceStatus)
    {
      this.alertService.info("No Invoice Found")
    }
    else 
    {
      this.payoutDetailForm.controls['invoiceNumber'].setValue(this.selectedInvoice.invoiceNumber);
      this.alertService.clear();
    }

  }

  }

  getPayoutDetail() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.payoutDetailForm.invalid) {
      return;
    }

    this.loading = true;
    this.dealerService.getDealerPayout({ "id": this.id, "recordCount": this.payoutDetailForm.get('recordCount').value })
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.aggrid.api.setRowData(x);
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;

        }
      });
    this.submitted = false;

  }
  getCertificateReport(dealerInvoice){
    this.isCertificateReportShow = false;
    this.invoice = new Invoice(dealerInvoice);
    this.isCertificateReportShow = true;
  }

  generateInvoice(dealerInvoice,invoiceType){
    const InvoiceMapKey = this.payoutDetailForm.get('invoiceYear').value + "-" + this.payoutDetailForm.get('invoiceMonth').value + "-NotSubmitted";
    // // console.log("Dealer: " + JSON.stringify(this.dealer.dealerInvoice));
    // var selectedInvoice = new Invoice(this.oldDealer.dealerInvoice[InvoiceMapKey]);
    // this.selectedInvoice.invoiceType = invoiceType;
    // dealerInvoice.invoiceType = invoiceType;

    // console.log("dealer id" + this.dealer.dealerId)
    this.dealerService.getDealerTotalCertSold({ "dealerId" : this.dealer.dealerId, "invoiceYear": dealerInvoice.invoiceYear , 
    "invoiceMonth" : dealerInvoice.invoiceMonth })
    .pipe(first())
    .subscribe(x => {
       this.dealer.dealerTotalCertSold = 0;
        x.map(a => this.dealer.dealerTotalCertSold =  this.dealer.dealerTotalCertSold + a.numberOfPolicySold);
        this.dealerService.PrintDealerSummary = x;



       console.log('in payout comp, returning data' + JSON.stringify(x));
    
    // console.log("Invoices: " + JSON.stringify(this.oldDealer));
    // console.log("input invoicetype: " + invoiceType);
    // console.log("invoicenumber: " + dealerInvoice.invoiceType);
    // console.log("selectedinvoicenumber: " + this.selectedInvoice.invoiceType);
    if (this.selectedInvoice.invoiceNumber)
    {
      // this.selectedInvoice.invoiceNumber = dealerInvoice.invoiceNumber;
      this.selectedInvoice.invoiceGeneratedDate = new Date().toString();
      this.selectedInvoice.invoiceType = invoiceType;
      this.selectedInvoice.invoiceStatus = "Ready for Print";
      this.dealer.dealerInvoice[InvoiceMapKey] = this.selectedInvoice;
      this.dealer.printInvoiceKey = InvoiceMapKey;
      this.dealerService.PrintDealer = this.dealer;
      // console.log("Dealerabc: " + JSON.stringify(this.dealer.dealerInvoice));
      this.router.navigate(['../users/printInvoice'], { relativeTo: null });

    }
    else
    {
    this.configDataService.getInvoiceId().pipe(first())
    .subscribe({
      next: (x) => {
        this.selectedInvoice.invoiceNumber = "INV-" + x;
        this.selectedInvoice.invoiceType = invoiceType;
        this.selectedInvoice.invoiceGeneratedDate = new Date().toString();
        this.selectedInvoice.invoiceStatus = "Ready for Print";
        this.dealer.dealerInvoice[InvoiceMapKey] = this.selectedInvoice;
        this.dealer.printInvoiceKey = InvoiceMapKey;
        this.dealerService.PrintDealer = this.dealer;
        //  console.log("Dealer2: " + JSON.stringify(this.dealerService.PrintDealer));
        this.router.navigate(['../users/printInvoice'], { relativeTo: null });

      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;

      }
    });
}
});
};
}