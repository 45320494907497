<div class="printPage" id="printRsaForm" style="font-family: Arial, Helvetica, sans-serif">
    <div class="page" >
        <div class="subpage" >
            <div class="container-fluid" >
                <div class="row" style="text-align: left; margin-top: 35px;">
                    <div class="col-md-8"> 
                        <img src="../assets/AllianceLogo.png" height="60px" style="align-self: left;">
                    </div>
                    <div class="col-md-4"> 
                        <div class="container-fluid">
                            <div class="row">
                                <small class="text-right">{{currentDate}} </small>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: left;background: rgb(36,43,134)">
                    <div class="col"> 
                        <h4 class="text-center" style="color: bisque;">  Alliance Assure - Certificate of Assistance </h4>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <strong class="text-center" >  CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
                    </div>
                </div>
            </div>
           <div class="container-fluid" style="margin-top: 6px;" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                            <tbody style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                                    <address>
                                        <strong>Corporate Address: Alliance Assure Private Limited</strong><br>
                                        #29, HSVP Shopping Complex, Sector 39,Gurugram (Haryana)-122003<br>
                                        PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC
                                    </address>
                                    </td>
                            <td >
                                <address>
                                    <strong>For Assistance, Please contact us at: </strong><br>
                                    Email ID: <a href="mailto::%20support@allianceassure.in"><strong>: support@allianceassure.in</strong></a><br>
                                    <a *ngIf="!printRsaNumber">Contact No: <strong>1800 212 7559</strong></a>
                                </address>
                           </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Tax Invoice Number: {{certificate.certificateNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Plan Type: {{certificate.planTotalAmount}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Effective Period: {{certificate.issueDate | date:'dd-MM-yyyy hh:mm:ss a':'+0530'}} - {{certificate.endDate |date:'dd-MM-yyyy'}} MIDNIGHT</strong>
                                </td>
                                <td>
                                    <strong> IMD Code: {{certificate.dealerId}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Name of Certificate Holder: {{certificate.firstName +  " " + certificate.lastName}}</strong>
                                </td>
                                <td>
                                    <strong> Dealer Name: {{certificate.dealerId}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong *ngIf="!(certificate.ageOrDob && certificate.ageOrDob == 'age')"> DOB: {{certificate.dob}}</strong>
                                    <strong *ngIf="(certificate.ageOrDob && certificate.ageOrDob == 'age')"> Age: {{certificate.age}}</strong>
                                </td>
                                <td>
                                    <strong> Gender: {{certificate.gender}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Mobile: {{certificate.contactNumber}}</strong>
                                </td>
                                <td>
                                    <strong> EmailId: {{certificate.email}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Address: {{certificate.address|slice:0:150}}</strong>
                                </td>
                                <td>
                                    <strong> City / District: {{certificate.city}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> State: {{certificate.state}}</strong>
                                </td>
                                <td>
                                    <strong> Vehicle Type: &nbsp; {{certificate.vehicleType? certificate.vehicleType: "Two Wheeler"}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> OEM: {{certificate.vehicleMake}} </strong>
                                </td>
                                <td>
                                    <strong> Model/Variant: {{certificate.model}} / {{certificate.variant}}</strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Vehicle Registration Number: {{certificate.registrationNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Manufacturing Year: {{certificate.vehicleYear}} </strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Engine Number: {{certificate.engineNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Chasis Number: {{certificate.chasisNumber}} </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="background-color: rgb(36,43,134);">
                    <div class="col" style="text-align: left;font-family: arial, helvetica, sans-serif;"> 
                        <strong class="text-left" style="text-align:left; color: bisque;"> Plan Features</strong>
                    </div>
                    <div class="col" style="text-align: right; padding-right: 50px;font-family: arial, helvetica, sans-serif;"> 
                        <strong class="text-right" style="color: bisque; text-align:right; " *ngIf="printRsaNumber"> Toll Free Road Side Assistance Number: 1800 212 7559</strong>
                    </div>
                </div>
            </div>  
            <div class="container-fluid" >
                <div class="row" >
                    <div class="col">
                        <table class="table table-condensed " width=100% height=250px style="text-align: left; margin-top: 10px; ">
                            <tbody style="font-size:medium;">
                                <tr *ngFor="let feature of rsaEvFeatures.planFeatures; ">
                                    <th *ngIf="feature.rowHighlight == false">{{feature.serialNo}}</th>
                                    <th *ngIf="feature.rowHighlight == true" style="background-color: bisque;"></th>
                                        <th *ngIf="feature.rowHighlight == true" style="background-color: bisque;">
                                        <strong style="font-family: arial, helvetica, sans-serif;"> {{feature.featureDescription}}</strong>
                                    </th>
                                    <th *ngIf="feature.rowHighlight == true" style="background-color: bisque;">
                                        <strong style="font-family: arial, helvetica, sans-serif;"> {{feature.featureValue}}</strong>
                                    </th>
                                    <td *ngIf="feature.rowHighlight == false">
                                        <strong style="font-family: arial, helvetica, sans-serif;"> {{feature.featureDescription}}</strong>
                                    </td>
                                    <td *ngIf="feature.rowHighlight == false">
                                        <strong style="font-family: arial, helvetica, sans-serif;"> {{feature.featureValue}}</strong>
                                    </td>
                                </tr>
                                </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
        
                                <div  *ngFor="let parasPostTable of rsaEvFeatures.planParasPostTable;">
                                    <div class="container-fluid" >
                                    <div class="row" style="text-align: left;">
                                        <div class="col"> 
                                            <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                                                <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                                    <tr>
                                                    <td>
                                            <strong>{{parasPostTable.paraHeading}}</strong>
                                            </td></tr></tbody> </table>  
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid" style="line-height: 15px;">
                                    <div class="row" >
                                        <div class="col"> 
                                            <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; " > {{parasPostTable.paraValue}}</small><br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" >
                    <div class="col-md-12" style="align-self: center;"> 
                        <table class="table table-condensed">
                            <tbody style="font-size: small; text-align: center;" *ngIf = "showGstAmount">
                                <tr>
                                    <td>
                                        <strong>Plan Amount (Rs)</strong>
                                    </td>
                                    <td>
                                        <strong>CGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>SGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>IGST(18%)</strong>
                                    </td>
                                    <td>
                                        <strong>Total Amount (Rs)</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{{certificate.planAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.CgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.SgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.IgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.planTotalAmount}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        <tbody *ngIf="!showGstAmount">
                            <tr>
                                <td>
                                    <strong>Product Plan</strong>
                                </td>
                                <td>
                                    <strong>PLAN &nbsp; {{certificate.planTotalAmount}}</strong>
                                </td>
                            </tr>
                        </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align:right; font-size: small;">
                    <div class="col"> 
                        <strong class="text-right"> For Alliance Assure Private Limited </strong><br>
                        <img src="../assets/signature.png" height="80px" width="200px" style="align-self: right; margin-right: 20px;"><br>
                        <strong class="text-right" style="padding-right: 60px;"> Authorised Signatory </strong>
                    </div>
                </div>
            </div>
              
        </div>
    </div>
</div>

