export class ReportSearch {
    certificateNumber: string;
    policyNumber: string;
    issueDateFrom: string;
    issueDateTo: string;
    firstName:string;
    lastName:string;
    userName:string;
    userId:string;
    aggregationType:string;
    dealerId:string;
    year:string;
    month:string;
    dealerGroupName:string;
    dealerState:string;
    chasisNumber:string;
    cpaPolicyCompany:string;
    contactNumber:string;

    public constructor(init?: Partial<ReportSearch>) {
        Object.assign(this, init);
    }
}