<div class="printPage2" id="printnewForm" style="font-family: sans-serif, arial, helvetica ">
    <div class="page" style="max-width: 100%; ">
        <div class="subpage">

            <div class="container-fluid">
                <div class="row" *ngIf="!canSeeNewIndiaLogo" style="text-align: left; margin-top: 40px;">
                    <div class="col-md-8">
                        <qrcode [qrdata]="certificate.certificateNumber" [width]="100" [errorCorrectionLevel]="'M'">
                        </qrcode>
                    </div>
                </div>
                <div class="row" *ngIf="canSeeNewIndiaLogo" style="text-align: left; margin-top: 40px;">
                    <div class="col-md-8">
                        <img src="../assets/NewIndiaLogo.png" height="100px" style="align-self: left;">
                    </div>
                </div>
        </div>
            <div class="container-fluid">
                <div class="row" style="text-align: left;background: rgb(36,43,134)">
                    <div class="col">
                        <h4 class="text-center" style="color: rgb(248, 248, 248);"> Policy Coverages </h4>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <strong class="text-center"> CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 6px;">
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                            <tbody
                                style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                                <!-- <tr>
                    <td>
                        <p style="margin-bottom: 2px;">
                            <strong>For any Claims related queries, Please reach out on above details:</strong><br>
                            The New India Assurance Company Limited<br>
                            87, M.G. Road, Fort, Mumbai - 400001
                        </p>
                </td>
                <td >
                    <p style="margin-bottom: 2px;">
                        <strong>In case of Claim, Please contact us at: </strong><br>
                        Toll Free Number: 1800 209 1415<br>
                        Email ID: <a href="mailto::%20tech.support@newindia.co.in"><strong>: tech.support@newindia.co.in</strong></a>
                    </p>
                </td>
                </tr> -->
                                <tr>
                                    <td>
                                        <strong> Policy Number: {{certificate.policyNumber}}</strong>
                                    </td>
                                    <td>
                                        <strong> Policy Holder: Alliance Assure Private Limited</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Name of Certificate Holder: {{certificate.firstName + " " +
                                             certificate.lastName}}</strong>
                                    </td>
                                    <td>
                                        <strong> Period of Insurance: {{certificate.issueDate | date:'dd-MM-yyyy
                                            hh:mm:ss a':'+0530'}} - {{certificate.endDate |date:'dd-MM-yyyy'}}
                                            MIDNIGHT</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong *ngIf="!(certificate.ageOrDob && certificate.ageOrDob == 'age')"> DOB:
                                            {{certificate.dob}}</strong>
                                        <strong *ngIf="(certificate.ageOrDob && certificate.ageOrDob == 'age')"> Age:
                                            {{certificate.age}}</strong>
                                    </td>
                                    <td>
                                        <strong> Gender: {{certificate.gender}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Mobile: {{certificate.contactNumber}}</strong>
                                    </td>
                                    <td>
                                        <strong> Insurer: THE NEW INDIA ASSURANCE CO. LTD </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Address: {{certificate.address|slice:0:150}}</strong>
                                    </td>
                                    <td>
                                        <strong> City / District: {{certificate.city}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> State: {{certificate.state}}</strong>
                                    </td>
                                    <td>
                                        <strong> Vehicle Type: &nbsp; {{certificate.vehicleType?
                                            certificate.vehicleType: "Two Wheeler"}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Vehicle Manufacturer: {{certificate.vehicleMake}} </strong>
                                    </td>
                                    <td>
                                        <strong> Model/Variant: {{certificate.model}} / {{certificate.variant}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Vehicle Registration Number:
                                            {{certificate.registrationNumber}}</strong>
                                    </td>
                                    <td>
                                        <strong> Manufacturing Year: {{certificate.vehicleYear}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Engine Number: {{certificate.engineNumber}}</strong>
                                    </td>
                                    <td>
                                        <strong> Chasis Number: {{certificate.chasisNumber}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Nominee Name: {{certificate.nomineeName}}</strong>
                                    </td>
                                    <td>
                                        <strong> Nominee Relationship: {{certificate.nomineeRelationship}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Nominee Gender: {{certificate.nomineeGender}}</strong>
                                    </td>
                                    <td>
                                        <strong> Nominee Age: {{certificate.nomineeAge}} </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> IMD Code: {{certificate.dealerId}} </strong>
                                    </td>
                                    <td>
                                        <strong> Personal Accident Insurance Amount: ₹ 15,00,000/-</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 14px ;">
                <div class="row">
                    <div class="col">
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif,Garamond,arial, helvetica, ; font-weight: 600; font-weight: 600;">
                            Rs.15,00,000/- (Accidental Death and Permanent Total Disability). Cover is valid only while
                            the insured is driving the vehicle covered under the Road Side Assistance plan mentioned
                            above </small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left;">
                    <div class="col">
                        <table class="table table-condensed"
                            style="text-align: left; border: 1px solid black; margin-bottom: 2px;">
                            <tbody
                                style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                                <tr>
                                    <td>
                                        <strong>Restriction of Coverage</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 14px ;">
                <div class="row">
                    <div class="col">
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif,arial, helvetica; font-weight: 600; ">
                            Accidental Death: It is strictly restricted to Death due to accident, while the owner of
                            vehicle driving the vehicle and vehicle is registered with Alliance Assure.</small><br>
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif,arial, helvetica; font-weight: 600; ">
                            Permanent Total Disability: It is strictly restricted to Disability due to accident, while
                            the owner of vehicle driving the vehicle and vehicle is registered with Alliance
                            Assure.</small><br>
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600; ">
                            Coverage is applicable to persons with age up to 70 years</small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left; margin-top: 10px black;">
                    <div class="col">
                        <table class="table table-condensed"
                            style="text-align: left; border: 1px solid black; margin-bottom: 2px;">
                            <tbody
                                style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                                <tr>
                                    <td>

                                        <strong>Coverage in Brief</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 14px ;">
                <div class="row">
                    <div class="col">
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;"> If
                            the Insured person meets with an accident and sustains any bodily injury during the policy
                            period which directly and independently of all other causes result in death / permanent
                            total disablement within 12 months from the date of accident resulting solely and directly
                            from accident then the company shall pay to the insured the sum set in the schedule to the
                            insureds persons nominee,beneficiary or legal representative.</small><br>
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            Accidental Death: 100% of cumulative Sum Insured (CSI)</small><br>
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            Permanent Total Disability: •Loss of Two Limbs/ Two Eyes or One Limb and One Eye: 100% of
                            CSI •Loss of One Limb or One Eye : 50% of CSI •Permanent Total Disablement from Injuries
                            other than those named above: 100% of CSI. Compensation will be paid either under Accidental
                            Death or under Permanent Total Disability and in no case, compensation will be paid under
                            both the heads. Please refer to policy for detail information on Policy coverage</small>
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            Compensation will be paid either under Accidental Death or under Permanent Total Disability
                            and in no case, compensation will be paid under both the heads. Please refer to policy for
                            detail information on Policy coverage</small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left; margin-top: 10px black;">
                    <div class="col">
                        <table class="table table-condensed"
                            style="text-align: left; border: 1px solid black; margin-bottom: 2px;">
                            <tbody
                                style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                                <tr>
                                    <td>

                                        <strong>Exclusions in Brief</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 14px ;">
                <div class="row">
                    <div class="col">
                        <small class="text-center"
                            style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">•
                            War, invasion, act of foreign enemy, hostilities (whether war be declared or not) civil war,
                            rebellion, revolution, insurrection, mutiny military or usurped power, confiscation,
                            seizure, capture, assault, restraint, nationalization, civil commotion or loot or pillage in
                            connection Herewith. • Ionizing radiation or contamination by radioactivity from any nuclear
                            fuel or from any nuclear waste from the
                            combustion of nuclear fuel. For the purpose of this exclusion, combustion shall include any
                            self-sustaining process of nuclear fission. • The radioactive, toxic, explosive or the
                            hazardous properties of any nuclear assembly or nuclear component or nuclear weapons
                            material. • Whilst engaging in Aviation or Ballooning whilst mounting into, dismounting from
                            or traveling in any balloon or aircraft • Participation in any kind of motor speed contest
                            (including trial, training and qualifying heats)• Hire or Reward  • Carriage of goods (other than
                            samples or personal luggage) • Accident resulting from violation of laws
                            of land • This Insurance does not cover any loss, damage, cost or expense directly or
                            indirectly arising out of -
                            Biological or chemical contamination, Missiles, bombs, grenades, explosives • Services on
                            duty with any Armed forces • Intentional self-injury, suicide, or attempted suicide •
                            venereal diseases, aids or insanity • Whilst under the influence of Alcohol or intoxicating
                            liquor or drugs. • Medical or surgical treatment • Whilst committing any breach of law with
                            criminal intent. • Child birth, pregnancy or other physical cause peculiar to the female
                            sex. • Terrorism / Terrorist Incident of whatsoever nature directly or indirectly caused by,
                            resulting from or in connection with any act of terrorism regardless of any other cause or
                            event contributing concurrently or in
                            any other sequence to the loss. Please refer to policy for detail information on exclusions
                            and other terms and conditions.
                        </small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left; margin-top: 10px black;">
                    <div class="col">
                        <table class="table table-condensed"
                            style="text-align: left; border: 1px solid black; margin-bottom: 5px;">
                            <tbody
                                style="font-size: small;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                                <tr>
                                    <td>
                                        <strong>Claims Process / Documentation</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 13px ;;">
                <div class="row">
                    <div class="col">
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">Upon
                            happening of any accident and/or injury which may give rise to a claim under this
                            policy:</small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">•
                            You shall give the notice to our call centre immediately and also intimate in writing to
                            support@allianceassure.in. In case of Death, written notice also of Death must, unless
                            reasonable cause is shown, be given before internment/ cremation and in any case, within one
                            calendar month after the Death. In the event of loss of sight or amputation of limbs,
                            written notice thereof must also be given within one calendar month after such loss of sight
                            or amputation.
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">•
                            All certificates, information and evidence from a Medical Practitioner or otherwise required
                            by us shall be provided by you.
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">• On
                            receipt of intimation from you regarding a claim under the policy, we are entitled to carry
                            out examination and ascertain details and in the event of Death get the post-mortem
                            examination done in respect of deceased person.
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">Following
                            documents shall be required in the event of a claim for Death:
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">•
                            Policy Copy • Duly filled up claims form • Original Death Certificate or Death certificate -
                            Notarized/ Attested by a gazetted officer, if applicable • Original F.I.R or F.I.R -
                            Notarized/ Attested by a
                            gazetted officer • Insured valid and effective driving license, in accordance with
                            provisions of Section 3 of Motor Vehicle Act 1988
                            • Police Final charge sheet/ Court Final order - Notarized/ attested by a Gazetted Officer -
                            if applicable • Spot Panchnama and Police Inquest report - Notarized/ Attested by a
                            gazetted officer, if applicable • Post Mortem Report - Notarized/ Attested by a gazetted
                            officer, if concluded • Viscera Analysis Report/ Chemical analysis report/ Forensic Science
                            Lab report
                            notarized/ Attested by gazetted officer • Other Document as per Case details - Complete
                            medical records including Death Summary; if hospitalized, Website Links/ Newspaper cuttings,
                            Other references. Such compensation shall be payable directly to his/her legal representatives • If claim amount is more than 1lakh, AML Documents - Pan Card Copy,
                            Residence Proof,2 Passport size colour photos of claimant • Cancel Cheque with NEFT Mandate
                            form - duly filled in by the claimant and bank
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">
                            • Any other document as required by the Company to investigate the Claim or Our obligation
                            to make payment for
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">
                            1. In respect of fatal claims, the payment is to be made to the assignee named under the
                            policy. If there is no assignee, the payment is made to the legal representative as
                            identified by Will / Probate/ Letter of Administration / Succession Certificate
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ;  font-weight: 600;">
                            2. Where the above documents are not available, the following procedure may be followed: -
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            a) An affidavit from the Claimant(s) that he/she (they) is (are) the legal heir(s) of the
                            deceased
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">b)
                            An affidavit from other near family members and relatives of the deceased that they have no
                            objection if the claim amount is paid to the claimant(s)
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">For
                            Permanent Total disablement/Permanent Partial Disablement
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">•
                            Policy Copy
                            • Duly filled up claims form
                            • Attending Doctors Report
                            • Copy of medical records including Investigation/ Lab Reports (X Ray, MRI etc.)
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Original FIR, Panchnama, Police Report where applicable
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Hospital discharge card
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Original Certificate from Doctor of Govt. Hospital stating the degree of disability
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • If claim amount is more than 1lakh, AML Documents - Pan Card Copy, Residence Proof,2
                            Passport size colour photos of claimant
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Cancel Cheque with NEFT Mandate form - duly filled in by the claimant and bank
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Claimant have to submit proof of gainful employment.
                        </small><br> <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • MLC is mandatory
                        </small><br>
                        <small class="text-center"
                            style="font-size: small ;font-family: sans-serif, arial, helvetica ; font-weight: 600;">
                            • Any other document as required by the Company to investigate the Claim or Our obligation
                            to make payment for
                        </small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left; margin-top: 10px black;">
                    <div class="col">

                        <table class="table table-condensed"
                            style="text-align: left; border: 1px solid black; margin-bottom: 2px;">
                            <tbody
                                style="font-size: small;font-family: Georgia, 'Times New Roman', Times, serif; font-weight: 600;">
                                <tr>
                                    <td>

                                        <strong>For Claims, Please Connect: Email: support@allianceassure.in, Email:
                                            info@allianceassure.in</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
