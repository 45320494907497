import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { PaymentRazorpayService} from '@app/_services/payment-razorpay.service';
import {ExternalLibraryService} from '@app/_services/external-library.service';
import { first } from 'rxjs/operators';
import {Payments} from '../../_models/payment';
declare var Razorpay: any;
@Component({
  selector: 'app-dealer-payment-gateway',
  templateUrl: './dealer-payment-gateway.component.html',
  styleUrls: ['./dealer-payment-gateway.component.less']
})
export class DealerPaymentGatewayComponent implements OnInit {

  id:string;
  paymentForm: FormGroup;
  paymentDetailForm: FormGroup;
  numberofRecords = [10,20,50,100,200,500]
  isPaymentShow = true;
  submitted=false;
  loading=false;
  isBalanceUpdated=false;
  payment:Payments;
  paymentDetails: Payments[];
  isPaymentAddFormShow = false;
  isPaymentDetailShow = false;
  currentDate = new Date();
  user:User;
  dealer: Dealer;
  transactionTypes = [{"id":"AddPayment","text":"Add Payment to Dealer Balance"}, {"id":"Payout","text":"Payout to dealer"}]

  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private configDataService: ConfigDataService,
    private paymentRazorpayService: PaymentRazorpayService,
    private accountService: AccountService,
    private razorpayService: ExternalLibraryService

  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
    this.paymentForm = this.formBuilder.group({
      paymentAmount: ['', Validators.required],
      acceptTerms:[false, Validators.requiredTrue],
      comments: ['',Validators.required],
          });   
    
    this.paymentDetailForm = this.formBuilder.group({
      recordCount: [10, Validators.required],
                });   
        
           }

  get pmntForm() { return this.paymentForm.controls; }
  get pmntDtlForm() { return this.paymentDetailForm.controls; }

  paymentShowToggle(){
    this.isPaymentShow = !this.isPaymentShow;
  }
  paymentPlanFormToggle(){
    this.isPaymentAddFormShow = !this.isPaymentAddFormShow;
    this.isBalanceUpdated = false;
    if (this.isPaymentAddFormShow)
      this.isPaymentDetailShow = false;

  }
  onPaymentSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }

    this.loading = true;
    this.user = this.accountService.userValue;
    this.payment = new Payments(this.paymentForm.value)
    this.payment.dealerId = this.id;
    this.payment.paymentDate = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);
    this.payment.userId = this.user.id;
    this.payment.userName = this.user.username;
    this.payment.paymentAmount = this.payment.paymentAmount * 100;

    this.paymentRazorpayService.createOrder(this.payment.paymentAmount,this.user.dealerId, this.payment.comments)
    .pipe(first())
    .subscribe({
        next: (x) => {
            x.handler =  this.razorPayResponseHandler.bind(this);
            var rzp1 = new Razorpay(x);
            rzp1.on('payment.failed',function(response){
              this.alertService.error(response.error.code);
              this.alertService.error(response.error.description);
              this.alertService.error(response.error.reason);

            })
            rzp1.open();
            console.log('Opened');
            this.loading = false;

            // this.createOrder()
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;

        }
    }); 
    this.submitted=false;
  }

  razorPayResponseHandler(response){
    console.log(response);
    this.paymentRazorpayService.savePayment(response)
    .pipe(first())
    .subscribe({
        next: (x) => {
          this.alertService.success("Dealer balance updated by amount " + x.amount/100 );
          alert("Dealer balance updated by amount " + x.amount/100 );
          window.location.reload();
          this.loading = false;
          this.submitted=false;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
            this.submitted=false;
          }
    }); 
  }

    onReset(){
    window.location.reload();
  }
  paymentDetailFormToggle(){
    this.isPaymentDetailShow = !this.isPaymentDetailShow;
    if (this.isPaymentDetailShow)
      this.isPaymentAddFormShow = false;
  }

  getPaymentDetail(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.paymentDetailForm.invalid) {
      return;
    }

    this.loading = true;
    this.dealerService.getDealerPayment({"id": this.id, "recordCount" : this.paymentDetailForm.get('recordCount').value})
    .pipe(first())
    .subscribe({
        next: (x) => {
          this.paymentDetails = x;
          this.loading = false;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;

        }
    });
    this.submitted=false;

  }
}
