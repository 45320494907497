import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { Payouts } from '@app/_models/payout';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-payout-report',
  templateUrl: './payout-report.component.html',
  styleUrls: ['./payout-report.component.less']
})
export class PayoutReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  certificateReportForm: FormGroup;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading= false;
  isAdjustedBalancePositive = false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  reportCertificateData : Payouts[];
  currentDate = new Date();
  reportCreated = false;
  dealer : Dealer;
  totalCredits = 0;
  totalDebits = 0;


  user: User;

  constructor(
    private formBuilder: FormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      balance: [''],
      adjustedBalance: [''],
      openingBalance:[''],
          });
    
    // if (this.user.role == "admin") {
    //         this.certificateReportForm.get('dealerId').setValidators(Validators.required);
    //       this.certificateReportForm.controls['dealerId'].updateValueAndValidity();
    //     }
    
    
    this.gridOptions.defaultColDef = {
          resizable: true,      
        };
      
    this.gridOptions.columnDefs = [
             {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
             ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
             ,{headerName: "Payout Date", field: "payoutDate",sortable: true, filter:true }
             ,{headerName: "Payout Amount", field: "payoutAmount",sortable: true, filter:true }
             ,{headerName: "Payout GST Amount", field: "payoutGSTAmount",sortable: true, filter:true }
             ,{headerName: "Actual Paid Amount", field: "actualPayoutAmount",sortable: true, filter:true }
             ,{headerName: "Actual GST Paid Amount", field: "actualPayoutGSTAmount",sortable: true, filter:true }
             ,{headerName: "Comments", field: "comments",sortable: true, filter:true }
             ,{headerName: "Account Received", field: "receiverAccountNumber",sortable: true, filter:true }
             ,{headerName: "Pan Number", field: "receiverPanNumber",sortable: true, filter:true }
             ,{headerName: "GST Number", field: "receiverGstNumber",sortable: true, filter:true }
             ,{headerName: "Invoice Number", field: "invoiceNumber",sortable: true, filter:true }
             ,{headerName: "Before Tx Payout Amount", field: "totalPayoutAmountBeforeTx",sortable: true, filter:true }
             ,{headerName: "Before Tx Payout GST Amount", field: "totalGSTPayoutAmountBeforeTx",sortable: true, filter:true }
             ,{headerName: "Before Tx Pending Amount", field: "totalPendingAmountBeforeTx",sortable: true, filter:true }
             ,{headerName: "Before Tx Pending GST Amount", field: "totalGSTPendingAmountBeforeTx",sortable: true, filter:true }
             ,{headerName: "Invoice Status", field: "invoiceDetails.invoiceStatus",sortable: true, filter:true }
             ,{headerName: "Invoice Generated Date", field: "invoiceDetails.invoiceGeneratedDate",sortable: true, filter:true }
             ,{headerName: "Invoice Paid Date", field: "invoiceDetails.invoicePaidDate",sortable: true, filter:true }
             ,{headerName: "Invoice Month Balance", field: "invoiceDetails.dealerLastBalance",sortable: true, filter:true }
             ,{headerName: "Total Receivable", field: "invoiceDetails.dealerTotalPayment",sortable: true, filter:true }
             ,{headerName: "Total GST Receivable", field: "invoiceDetails.dealerTotalGstPayment",sortable: true, filter:true }
             ,{headerName: "Paid Receivable", field: "invoiceDetails.dealerTotalRefund",sortable: true, filter:true }
             ,{headerName: "Paid GST Receivable", field: "invoiceDetails.dealerTotalGstRefund",sortable: true, filter:true }
             ,{headerName: "Pending Receivable", field: "invoiceDetails.dealerPendingRefund",sortable: true, filter:true }
             ,{headerName: "Pending GST Receivable", field: "invoiceDetails.dealerGstPendingRefund",sortable: true, filter:true }
                  ]
          this.gridOptions.rowData = [];
          this.gridReady = true;
      
          this.certificateReportForm.get('issueDateFrom').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
          });
      
          this.certificateReportForm.get('issueDateTo').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
            //console.log("issueDateToSearch: " + this.issueDateToSearch)
          });
      
    
        }
            // convenience getter for easy access to form fields
            get f() { return this.certificateReportForm.controls; }

            onSubmit(){
              this.submitted = true;
              this.loading = true;
              this.reportCreated = false;
              if (this.certificateReportForm.invalid) {
                this.loading = false;
                return;
              }
              
              this.alertService.clear();
              if (this.user.role !="admin" && this.user.role !="dealerOwner")
              {
              this.alertService.error("Unauthorized access");
              this.submitted = false;
              this.loading = false;
              return;              
              }
              this.reportSearch = new ReportSearch();
              if (this.issueDateFromSearch == undefined)
              {
                this.reportSearch.issueDateFrom = "0001-01-01";
                this.issueDateFromSearch = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);; 
              }
              else
              this.reportSearch.issueDateFrom = this.issueDateFromSearch;
          
              if (this.issueDateToSearch == undefined)
              {
                this.reportSearch.issueDateTo = "9999-99-99";
              }
              else
              this.reportSearch.issueDateTo = this.issueDateToSearch;
              this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
              this.totalCredits = 0;
              this.totalDebits = 0;
              this.searchService.getPayoutReportDetails(this.reportSearch)
              .pipe(first())
              .subscribe({
                next: (x) => {
                  // console.log("output: " + JSON.stringify(x));
                  this.aggrid.api.setRowData(x);
                  this.loading = false;
                },
                error: error => {
                  this.alertService.error(error)
                  this.loading = false;
                }
              })  
                  
            }  
            onReset(){
              this.reportCreated = false;
              this.submitted = false;
              this.loading = false;

            }
            exportToExcel(){
              const params = {
                columnGroups: true,
                allColumns: true,
                fileName: 'excelReport',
           //     columnSeparator: document.querySelector("#columnSeparator").value
              };
              this.aggrid.api.exportDataAsCsv(params);
              // this.aggrid.api.exportDataAsExcel(params);
            }
                 

}
