import { Component, OnInit } from '@angular/core';
import { DealerService } from '../_services/dealer-service';
import { first } from 'rxjs/operators';
import { Dealer } from '@app/_models/dealer';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
})
export class DealerListComponent implements OnInit {
  dealers : Dealer[];
  serviceDealers : Dealer[];
  form: FormGroup;
  regexp: RegExp;
  idRegexp = new RegExp("",'i');
  stateRegexp = new RegExp("",'i');
  nameRegexp = new RegExp("",'i');
  cityRegexp = new RegExp("",'i');
  groupRegexp = new RegExp("",'i');
  dealerTypes = ['All','Package','Non Package']

  constructor(private dealerService: DealerService    
    ,    private formBuilder: FormBuilder,
    ) { }

  ngOnInit(): void {
    this.dealerService.getAll()
    .pipe(first())
    .subscribe((dealers) => 
    {this.serviceDealers = dealers;
      this.dealers = dealers;
    });
    this.form = this.formBuilder.group({
      dealerId: [''],
      dealerName: [''],
      dealerGroupName: [''],
      dealerCity: [''],
      dealerState: [''],
      dealerType:['']
    });

    this.form.get('dealerId').valueChanges.subscribe((value) => {
        this.idRegexp = new RegExp(value,'i'); 
        this.dealers = this.serviceDealers.filter(a => {
          return this.idRegexp.test(a.dealerId) && this.nameRegexp.test(a.dealerName) && this.groupRegexp.test(a.dealerGroupName) && this.cityRegexp.test(a.dealerCity);
        })
     })

     this.form.get('dealerName').valueChanges.subscribe((value) => {
      this.nameRegexp = new RegExp(value,'i'); 
      this.dealers = this.serviceDealers.filter(a => {
        return this.idRegexp.test(a.dealerId) && this.nameRegexp.test(a.dealerName) && this.groupRegexp.test(a.dealerGroupName) && this.cityRegexp.test(a.dealerCity) && this.stateRegexp.test(a.dealerState);
      })
   })

   this.form.get('dealerGroupName').valueChanges.subscribe((value) => {
    this.groupRegexp = new RegExp(value,'i'); 
    this.dealers = this.serviceDealers.filter(a => {
      return this.idRegexp.test(a.dealerId) && this.nameRegexp.test(a.dealerName) && this.groupRegexp.test(a.dealerGroupName) && this.cityRegexp.test(a.dealerCity) && this.stateRegexp.test(a.dealerState);
    })
 })

 this.form.get('dealerCity').valueChanges.subscribe((value) => {
  this.cityRegexp = new RegExp(value,'i'); 
  this.dealers = this.serviceDealers.filter(a => {
    return this.idRegexp.test(a.dealerId) && this.nameRegexp.test(a.dealerName) && this.groupRegexp.test(a.dealerGroupName) && this.cityRegexp.test(a.dealerCity) && this.stateRegexp.test(a.dealerState);
  })
})

this.form.get('dealerType').valueChanges.subscribe((value) => {
  if (value == this.dealerTypes[1])
  {
    this.dealers = this.serviceDealers.filter(a => a.dealerCreatePackage && a.dealerCreatePackage == true)
  }
  else 
  {
    if (value == this.dealerTypes[2])
    {
    this.dealers = this.serviceDealers.filter(a => !(a.dealerCreatePackage && a.dealerCreatePackage == true) )
    }
    else
    {
      this.dealers = this.serviceDealers
    }
  }
})

this.form.get('dealerState').valueChanges.subscribe((value) => {
  this.stateRegexp = new RegExp(value,'i'); 
  this.dealers = this.serviceDealers.filter(a => {
    return this.idRegexp.test(a.dealerId) && this.nameRegexp.test(a.dealerName) && this.groupRegexp.test(a.dealerGroupName) && this.cityRegexp.test(a.dealerCity) && this.stateRegexp.test(a.dealerState);
  })
})

  }


}
