<div class="container-fluid" [ngClass]="{ 'bg-light': user }" style="overflow:scroll">
    <!-- nav -->

<!-- <nav class="navbar navbar-expand navbar-dark" style="background-color: #17948e;" *ngIf="user">
    <div class="container-fluid" style="background-color: #17948e;" > -->
        <nav class="navbar navbar-expand navbar-dark"  *ngIf="user">
            <div class="container-fluid" >
                        <a class="navbar-brand" href="#">
            <img src="../assets/AllianceLogo.png" height="40px">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="user.role != 'rsaOperation'">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active"
                        *ngIf="user.role == 'admin'">Dealer</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/users/viewDealer" routerLinkActive="active"
                        *ngIf="user.role == 'dealerOwner'">Dealer</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/users/dealerPayout/" routerLinkActive="active"
                        *ngIf="(user.role == 'dealerOwner' && showReceivableTab)">Receivable</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/issueCertificate" routerLinkActive="active">Issue
                        Certificate</a>
                </li>
                <li class="nav-item dropdown" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('ExcelUpload'))">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Bulk Upload
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/issueCertificate/bulkIndividual"
                                routerLinkActive="active">Individual Policy</a></li>
                                </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/reports/printCertificate" routerLinkActive="active">Print
                        Certificate</a>
                </li>
                <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
                    <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active">Renew
                        Certificate</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Reports
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/reports/certificateReport"
                                routerLinkActive="active">Certificate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin' || user.role == 'employee'">Dealer Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerAggregateReport"
                                routerLinkActive="active" *ngIf="user.role == 'admin' || user.role == 'employee'">Dealer
                                Aggregate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/managerAggregateReport"
                                    routerLinkActive="active" *ngIf="user.role == 'manager' || user.role == 'dealerOwner'">
                                    Aggregate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/rsaCertificate" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Rsa Certificate</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/endorsementReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Endorsement Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerBalanceReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport'))">Dealer
                                Balance Details</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/transactionReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Payment Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/invoiceReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Invoice Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/payoutReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Payout Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/certificateReport/NewIndiaPolicy"
                                routerLinkActive="active" *ngIf="user.role == 'admin'">New India Report</a></li>
                                <li><a class="dropdown-item" routerLink="/reports/policyBalanceReport" routerLinkActive="active"
                                    *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('Can View Policy Balance Details Report') && dealer.dealerCreatePackage)">Policy
                                    Balance Details</a></li>
                                    <li><a class="dropdown-item" routerLink="/reports/totalBalanceReport" routerLinkActive="active"
                                        *ngIf="user.role == 'admin' ||  (user.specialRoleAccess && user.specialRoleAccess.includes('Can View Package Balance Detail Report') && dealer.dealerCreatePackage)">Package
                                        Balance Details</a></li>
                            </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAnalytics" role="button"  *ngIf="user.role == 'admin'"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Analytics
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownAnalytics">
                        <li>
                            <a class="dropdown-item" routerLink="/analytics/numberOfPolicies" routerLinkActive="active"
                               >Number of Policies</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/analytics/receivable" routerLinkActive="active"
                                >Receivable</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" *ngIf="user.role == 'admin'">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownConfigUpdate" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Configuration Update
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownConfigUpdate">
                        <li *ngFor="let polMaintain of masterPolicyMaintain">
                            <a class="dropdown-item" routerLink={{polMaintain.url}}
                                routerLinkActive="active">{{polMaintain.heading}}</a>
                        </li>
                        <div class="dropdown-divider"></div>
                        <li *ngFor="let serMaintain of seriesMaintain">
                            <a class="dropdown-item" routerLink={{serMaintain.url}}
                                routerLinkActive="active">{{serMaintain.heading}}</a>
                        </li>                        
                         <div class="dropdown-divider"></div>
                        <li *ngFor="let setMaintain of setupMaintain">
                            <a class="dropdown-item" routerLink={{setMaintain.url}}
                                routerLinkActive="active">{{setMaintain.heading}}</a>
                        </li>  
                    </ul>
                </li>
                <li class="nav-item" *ngIf="user.role == 'manager'">
                    <a class="nav-item nav-link" routerLink="/users/userList" routerLinkActive="active">User Maintainence</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="user.role == 'rsaOperation'">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item" >
                    <a class="nav-item nav-link" routerLink="/issueCertificate/rsa" routerLinkActive="active">RSA</a>
                </li>
                <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
                    <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active">Renew
                        Certificate</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>
         <!-- In navbar add username and logout button in last  -->
         
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <i class="fa fa-user nav-item nav-link" style="margin-top: 5px;"></i>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" (click)="logout()">{{" " + user.firstName + " " +
                    user.lastName}}</a>
            </li>
            </ul>
        <!-- <div class="navbar navbar-dark">
            <ul>
                <li class="nav-item">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                </li>
            </ul>
            <p>
                <i class="fa fa-user" style="margin-right: 5px; "></i>
                <a class="nav-item " (click)="logout()">&nbsp;{{" " + user.firstName + " " +
                    user.lastName}}</a>
            </p>
        </div> -->
    </div>
</nav>

<!-- main app container -->
<div id="appContainer">
    <alert></alert>
    <router-outlet></router-outlet>
    <alert></alert>
</div>

<!-- credits -->
<div class="text-center mt-4">
    <p>
        For any support reach out at <a href="">support@allianceassure.in</a>
    </p>
    <p> <img src="../assets/AllianceLogo.png" height="80px">
        <a href="https://AllianceAssure.com" target="_top">AllianceAssure.com</a>
    </p> <br>
    <small><a href="https://allianceassure.com/terms-conditions/">TERMS AND CONDITIONS </a>&nbsp;<a href="https://allianceassure.com/privacy-policy/">| PRIVACY
            POLICY |</a>&nbsp;<a style="color: #0d6efd;text-decoration-line:underline" (click)="cancellationPolicyToggle()"> CANCELLATION/REFUND POLICY |</a>&nbsp; <a
            href="https://allianceassure.com/contact/"> CONTACT US |</a>&nbsp;<a
            href="https://allianceassure.com/"> ABOUT US |</a> </small>
    <br> 
    <small *ngIf="showCancellationPolicy">
        THERE IS NO CANCELLATION OPTION FOR THE END USERS AFTER PAYMENT IS MADE. IN CASE OF DUPLICATE PAYMENT, KINDLY
        APPROACH US AT
        SUPPORT@ALLIANCEASSURE.IN .FOR REFUND WITH PROOF OF THE TRANSACTION REFERENCE/ YOUR
        BANK STATEMENT. REFUND WILL BE PROCESSED WITHIN 10-15 WORKING DAYS, RESPECTIVE PAYMENT
        GATEWAY WILL SEND BACK TO THE ISSUING BANK [USER’S CARD BANKER] IN BATCHES FOR
        PROCESSING, WHICH SHOULD APPROXIXMATELY TAKE 10-15 WORKING DAYS, DEPENDING ON ISSUING
        BANKS POLICIES. HOWEVER, ALLIANCE ASSURE PVT LTD. IS NOT RESPONSIBLE FOR ANY DELAY ON THE
        BANK SIDE.
    </small>
</div>
</div>
