import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { PlanDetails } from '@app/_models/planDetails';
import { MakeModel } from '@app/_models/makeModel';
import { User } from '@app/_models';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';

@Component({ templateUrl: 'add-edit.component.html' })
export class AddEditComponent implements OnInit {
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    roles:string[];
    plans:PlanDetails[];
    makes: MakeModel[];
    specialRoles: string[];
    user:User;
    dealerList:Dealer[];

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private configDataService: ConfigDataService,
        private dealerService: DealerService
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;
        this.user = this.accountService.userValue;
        this.configDataService.getSpecialRoles().subscribe(x => this.specialRoles = x);
        this.dealerService.getAll().subscribe(
            x => {
                this.dealerList = x.sort((a,b) => ((a.dealerGroupName + "-" + a.dealerName) > (b.dealerGroupName + "-" + b.dealerName)? 1:-1))
            }
        )
        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isAddMode) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', passwordValidators],
            role: ['', Validators.required],
            dealerId: ['', Validators.required],
            specialRoleAccess: [''],
            employeeDealerList: ['']
                });

        if (!this.isAddMode) {
            this.accountService.getById(this.id)
                .pipe(first())
                .subscribe(x => this.form.patchValue(x));
            }
        else {
            this.form.controls['dealerId'].setValue(this.route.snapshot.params['dealerId']);
        }
            if(this.user.role == 'admin')
                this.configDataService.getRoles().subscribe((x) => {
                    this.roles = x;
                })
            else {
                this.roles = ["executive", "manager"];                
                this.form.controls['dealerId'].setValue(this.user.dealerId);
                this.form.controls['dealerId'].disable();
                }

        }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        console.log(this.form.getRawValue());
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createUser();
        } else {
            this.updateUser();
        }
    }

    private createUser() {
        this.accountService.register(this.form.getRawValue())
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('User added successfully', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateUser() {
        this.accountService.update(this.id, this.form.getRawValue())
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.router.navigate(['../../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
