import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportLayoutComponent } from './report-layout/report-layout.component';
import { CertificateReportComponent } from './certificate-report/certificate-report.component';
import {ReportsRoutingModule} from './reports-routing.module'
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { PrintCertificateComponent } from './print-certificate/print-certificate.component';
import { DealerReportComponent } from './dealer-report/dealer-report.component';
import { DealerAggregatedReportComponent } from './dealer-aggregated-report/dealer-aggregated-report.component';
import { RsaCertificateReportComponent } from './rsa-certificate-report/rsa-certificate-report.component';
import { DealerBalanceReportComponent } from './dealer-balance-report/dealer-balance-report.component';
import { PaymentPayoutReportComponent } from './payment-payout-report/payment-payout-report.component';
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { PayoutReportComponent } from './payout-report/payout-report.component';
import { EndorsementReportComponent } from './endorsement-report/endorsement-report.component';
import { ManagerAggregateReportComponent } from './manager-aggregate-report/manager-aggregate-report.component';
import { PackageReportComponent } from './package-report/package-report.component';
import { TotalBalanceReportComponent } from './total-balance-report/total-balance-report.component';




@NgModule({
  declarations: [ReportLayoutComponent, CertificateReportComponent, PrintCertificateComponent, DealerReportComponent,DealerAggregatedReportComponent, RsaCertificateReportComponent, DealerBalanceReportComponent, PaymentPayoutReportComponent, InvoiceReportComponent, PayoutReportComponent, EndorsementReportComponent, ManagerAggregateReportComponent, PackageReportComponent, TotalBalanceReportComponent],
  imports: [
    CommonModule
    ,ReactiveFormsModule
    ,ReportsRoutingModule
    ,NgbModule
    ,AgGridModule.withComponents([])
  ]
})
export class ReportsModule { }
