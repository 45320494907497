<h1 *ngIf="!renewCertificate">Print Certificate</h1>
<h1 *ngIf="renewCertificate">Renew Certificate</h1>
<form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
         <div class="form-group col"> 
            <label for="certificateNumber">Certificate Number</label>
            <input type="text" formControlName="certificateNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
        </div>
        <div class="form-group col">
            <label for="chasisNumber">Chasis Number</label>
            <input type="text" formControlName="chasisNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.chasisNumber.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="firstName">Client First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="lastName">Client Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        </div>
        <div class="form-group col" *ngIf="renewCertificate">
            <label for="contactNumber">Client Contact Number</label>
            <input type="text" formControlName="contactNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="userName">Executive User Name</label>
            <input type="text" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="issueDateFrom">Issue Date From</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateFrom"   ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateFrom.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateFrom.errors.required">Issue Date From</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="issueDateTo">Issue Date To</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateTo"   ngbDatepicker #issueDateTo="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="issueDateTo.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateTo.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateTo.errors.required">Issue Date From</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
        </button><a>&nbsp;&nbsp;</a>
        <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
            Reset
        </button>
    </div>
    </div>
    <div class="form-row" style="width:100%; overflow: scroll;">
        <div class="form-group col" style="width:100%; overflow: scroll;">
        


<table class="table table-striped" style="width:100%; overflow: scroll;">
    <thead>
        <tr>
            <th style="width:10%;">Certificate Number</th>
            <th style="width:10%;" *ngIf="user.role == 'admin'">Dealer Name</th>
            <th style="width:10%;">Owner Name</th>
            <th style="width:10%;">Chasis Number</th>
            <th *ngIf="user.role != 'admin'" style="width:10%;">Bike Model</th>
            <th style="width:10%;">Bike Issue Date</th>
            <th *ngIf="user.role == 'admin'" style="width:10%;">Policy Type</th>
            <th style="width:10%;">Certificate Status</th>
            <th style="width:10%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let certificate of certificates">
            <td>{{certificate.certificateNumber}}</td>
            <td *ngIf="user.role == 'admin'">{{certificate.dealerName}}</td>
            <td>{{certificate.firstName +  " " + certificate.lastName}}</td>
            <td>{{certificate.chasisNumber}}</td>
            <td *ngIf="user.role != 'admin'">{{certificate.model}}</td>
            <td>{{certificate.issueDateString}}</td>
            <td *ngIf="user.role == 'admin'">{{certificate.policyType}}</td>
            <td>{{certificate.certificateStatus}}</td>
            <td style="white-space: nowrap">
                <a (click)="printCertificate(certificate)" class="btn btn-sm btn-primary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && !renewCertificate">Print Certificate</a>
                    <button (click)="cancelCertificate(certificate)" class="btn btn-sm btn-danger mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && (user.role == 'admin' || renewCertificate) " [disabled]="certificate.certificateStatus != 'Submitted'">
                    <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!certificate.isDeleting">Cancel Certificate</span>
                    </button>

                    <button (click)="endorseCertificate(certificate)" class="btn btn-sm btn-secondary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && (user.role == 'admin' || certificate.createdDate >= startDate || renewCertificate)" [disabled]="certificate.certificateStatus != 'Submitted'">
                    <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!certificate.isDeleting">Endorse Certificate</span>
                    </button>
                    <button (click)="renewCertificateAction(certificate)" class="btn btn-sm btn-primary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && renewCertificate">
                        <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                        <span *ngIf="!certificate.isDeleting">Renew</span>
                        </button>
                </td>
        </tr>
        <tr *ngIf="loading">
            <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>
</div>
</div>


</form>



