<nav aria-label="breadcrumb" (click)="paymentShowToggle()">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">
        <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isPaymentShow"></i>
        <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isPaymentShow"></i>
        <p class="h5"> Add Payment</p></li>
    </ol>
  </nav>
  <div *ngIf = "isPaymentShow">
<form [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
    <div class="form-row">
      <div class="form-group col">
        <label for="paymentAmount">Payment Amount</label>
        <input type="number" formControlName="paymentAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && pmntForm.paymentAmount.errors }" />
        <div *ngIf="submitted && pmntForm.paymentAmount.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.paymentAmount.errors.required">Payment Amount is required</div>
        </div>
    </div>
    <div class="form-group col">
            <label for="comments">Comments</label>
            <input type="text" formControlName="comments" class="form-control" [ngClass]="{ 'is-invalid': submitted && pmntForm.comments.errors }" />
            <div *ngIf="submitted && pmntForm.comments.errors" class="invalid-feedback">
                <div *ngIf="pmntForm.comments.errors.required">Comments is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <div class="form-group form-check">
          <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && pmntForm.acceptTerms.errors }" />
          <label for="acceptTerms" class="form-check-label">I agree with the <a href="https://allianceassure.com/terms-conditions/"> Terms & Conditions </a>&nbsp;by proceeding with this payment</label>
          <div *ngIf="submitted && pmntForm.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
      </div>

      </div>
    </div>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Pay
      </button><a>&nbsp;&nbsp;</a>
      <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
        Refresh Page
    </button>
  </div>
  <div class="form-group">
    <p *ngIf="isBalanceUpdated"> Balance Updated!!! Please refresh page to see new balance.</p>
</div>

</form>
</div>

