<div class="container">
    <div class="row">
        <div class="col">
            <h1 >Issue Certificate</h1>
        </div>
        <div class="col">
        </div>

        <div class="col" *ngIf="!(user.specialRoleAccess && user.specialRoleAccess.includes('CannotViewDealerBalance'))">
            <div class="row">
                <div class="col">
            <h4 style= "position: relative;">Balance: &nbsp; </h4>
        </div>
        <div class="col">
            <h4 style= "position: relative; background-color:rgb(121, 201, 190);" >{{user.specialRoleAccess.includes("Show Total Package Balance")? dealer.dealerBalance + (dealer.dealerPolicyBalance ? dealer.dealerPolicyBalance : 0):dealer.dealerBalance}}</h4>
        </div>
        <div class="col">
            <button  class="btn btn-secondary mr-1" type="reset" (click)="paymentGatewayShowToggle()" *ngIf="showPaymentButton">
                Add Money
            </button>
        </div>
        <div class="container" *ngIf="showPaymentGatewayForm">
                <app-dealer-payment-gateway></app-dealer-payment-gateway>
            </div>
        </div>
        </div>
    </div>
</div>
<br>
<nav aria-label="breadcrumb" >
    <!-- <ol class="breadcrumb" style="background-color: rgb(134, 224, 212);">
        <li class="breadcrumb-item active" aria-current="page" style="padding: 10px;">
        <p class="fa fa-car" style="margin: 5px; "></p>
        <p class="h5" style="margin-left: 5px;"> Vehicle Details</p></li>
    </ol> -->
    <ol class="breadcrumb" >
        <li class="breadcrumb-item active" aria-current="page" >
            <p class="fa fa-car" style="margin: 5px; "></p>
            <p class="h5" style="margin-left: 5px;"> Vehicle Details</p></li>
        </ol>
  </nav>
<form [formGroup]="certficateForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="vehicleMake">Make</label>
            <select class="form-select" formControlName="vehicleMake" [ngClass]="{ 'is-invalid': submitted && f.vehicleMake.errors }" >
                <option *ngFor="let vehicleMake of makeModel" [ngValue]="vehicleMake.make">
                  {{ vehicleMake.make }}
                </option>
              </select>
              <div *ngIf="submitted && f.vehicleMake.errors" class="invalid-feedback">
                <div *ngIf="f.vehicleMake.errors.required">Make needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="model">Model</label>
            <select class="form-select" formControlName="model" [ngClass]="{ 'is-invalid': submitted && f.model.errors }">
                <option *ngFor="let model of models" [ngValue]="model">
                  {{ model }}
                </option>
              </select>
            <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
                <div *ngIf="f.model.errors.required">Model is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="type">Type</label>
            <select class="form-select" formControlName="type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }" >
                <option *ngFor="let type of types " [ngValue]="type">
                  {{ type }}
                </option>
              </select>
            <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">Type is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="variant">Variant</label>
            <input type="text" formControlName="variant" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.variant.errors }" />
            <div *ngIf="submitted && f.variant.errors" class="invalid-feedback">
                <div *ngIf="f.variant.errors.required">Please provide missing model information here</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="vehicleYear">Manufacturing Year</label>
            <select class="form-select" formControlName="vehicleYear" [ngClass]="{ 'is-invalid': submitted && f.vehicleYear.errors }" >
                <option *ngFor="let vehicleYear of years " [ngValue]="vehicleYear">
                  {{ vehicleYear }}
                </option>
              </select>
            <div *ngIf="submitted && f.vehicleYear.errors" class="invalid-feedback">
                <div *ngIf="f.vehicleYear.errors.required">Manufacturing year is required</div>
            </div>
        </div>
            <div class="form-group col">
            <label for="registrationNumber">Registration Number</label>
            <input type="text" formControlName="registrationNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.registrationNumber.errors }" />
            <div *ngIf="submitted && f.registrationNumber.errors" class="invalid-feedback">
                <div *ngIf="f.registrationNumber.errors.required">Registration Number is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="chasisNumber">Chasis Number</label>
            <input type="text" formControlName="chasisNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.chasisNumber.errors }" />
            <div *ngIf="submitted && f.chasisNumber.errors" class="invalid-feedback">
                <div *ngIf="f.chasisNumber.errors.required">Chasis Number is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="engineNumber">Engine Number</label>
            <input type="text" formControlName="engineNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.engineNumber.errors }" />
            <div *ngIf="submitted && f.engineNumber.errors" class="invalid-feedback">
                <div *ngIf="f.engineNumber.errors.required">Engine Number is required</div>
            </div>
        </div>
    </div>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page" >
            <p class="fa fa-user" style="margin: 5px; "></p>
            <p class="h5" style="margin-left: 5px;" *ngIf = !isCorporate> Customer Details</p>
            <p class="h5" style="margin-left: 5px;" *ngIf = isCorporate> Corporate Details</p></li>
        </ol>
      </nav>
    <div class="form-row">
        <div class="form-group col">
            <label for="clientType">Client Type</label>
            <select class="form-select" formControlName="clientType" [ngClass]="{ 'is-invalid': submitted && f.clientType.errors }" >
                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType">
                  {{ clientType }}
                </option>
              </select>
              <div *ngIf="submitted && f.clientType.errors" class="invalid-feedback">
                <div *ngIf="f.clientType.errors.required">Client Type needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="firstName" *ngIf = !isCorporate>First Name</label>
            <label for="firstName" *ngIf = isCorporate>Organisation Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">Name is required</div>
            </div>
        </div>
        <!-- <div class="form-group col">
            <label for="middleName">Middle Name</label>
            <input type="text" formControlName="middleName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.middleName.errors }" />
            <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
                <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
            </div>
        </div> -->
        <div class="form-group col" *ngIf = !isCorporate>
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="email" *ngIf = !isCorporate>Email</label>
            <label for="email" *ngIf = isCorporate>Organisation Email</label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email id is invalid</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="contactNumber">
                Contact Number
            </label>
            <input type="number" formControlName="contactNumber" class="form-control" maxlength="10" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
            <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.contactNumber.errors.required">Contact Number is required</div>
                <div *ngIf="f.contactNumber.errors.minlength">Contact Number must be at least 6 characters</div>
            </div>
        </div>
    </div>
    <div class="form-row" *ngIf = !isCorporate>
        <div class="form-group col">
            <label for="gender">Gender</label>
            <select class="form-select" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }" >
                <option *ngFor="let gender of genders " [ngValue]="gender">
                  {{ gender }}
                </option>
              </select>
            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('canSeeAge')">
            <label for="ageOrDob">Age or Date of Birth</label>
            <div class="row">
                <div class="col">
                    <input type="radio" formControlName="ageOrDob" [value]="ageOrDobValue[1]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Age</span>
                </div>
                <div class="col">
                    <input type="radio" formControlName="ageOrDob"  [value]="ageOrDobValue[0]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Date of Birth</span>
            </div>
        </div>            
        </div>

        <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[1]">
            <label for="age">Age</label>
            <div class="input-group">
                <input type="number" formControlName="age" class="form-control" maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.age.errors }" />
                <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
                    <div *ngIf="f.age.errors.required"> Age is required</div>
                    <div *ngIf="f.age.errors.max">Maximum age allowed is 80</div>
                    <div *ngIf="f.age.errors.min">Minimum age allowed is 15</div>
                </div>
                  </div>            
        </div>
        <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[0]">
            <label for="dob">Date of Birth</label>
            <div class="input-group">
                <input class="form-control" formControlName="dob"  [minDate]="minDobDateModel" [maxDate]="maxDobDateModel" placeholder="DD/MM/YYYY" ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
                <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                    <div >Date of birth is required and age should be between 15 to 80 years</div>
                </div>
              </div>            
        </div>
        <!-- <div class="form-group col">
            <label for="gstin">
                GSTIN
            </label>
            <input type="text" formControlName="gstin" maxlength="15" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gstin.errors }" />
            <div *ngIf="submitted && f.gstin.errors" class="invalid-feedback">
                <div *ngIf="f.gstin.errors.required">Gstin is required</div>
            </div>
        </div> -->
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="address" *ngIf = !isCorporate>Address</label>
            <label for="address" *ngIf = isCorporate>Organisation Address</label>
            <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required">Address is required</div>
            </div>
        </div>
            <div class="form-group col">
                <label for="state">State</label>
                <select class="form-select" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" >
                    <option *ngFor="let state of stateCity" [ngValue]="state.state">
                      {{ state.state }}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                    <div *ngIf="f.state.errors.required">State needs to be selected</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="city">City</label>
                <select class="form-select" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                    <option *ngFor="let city of cities" [ngValue]="city">
                      {{ city }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
            </div>    
    </div>
    <div *ngIf="dealer.dealerCreatePackage">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
            <li class="breadcrumb-item active" aria-current="page" >
                <i class="fa fa-user" style="margin: 5px; "></i>
            <p class="h5" style="margin-left: 5px;" > Policy Details</p>
        </ol>
      </nav>
    </div>
    <div [ngClass]="{'alert alert-success':dealer.dealerPolicyBalance > 1000,
        'alert alert-warning':(dealer.dealerPolicyBalance <= 1000 && dealer.dealerPolicyBalance > 0),
        'alert alert-danger':dealer.dealerPolicyBalance <= 0}" role="alert" *ngIf="(user.specialRoleAccess && user.specialRoleAccess.includes('CanSeePolicyBalance'))">
         Policy Balance: {{dealer.dealerPolicyBalance}}
    </div>
    <div class="form-row" *ngIf="dealer.dealerCreatePackage">
        <div class="form-group col">
        <label for="insPolicyAmount">Policy Amount</label>
        <input type="number" formControlName="insPolicyAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insPolicyAmount.errors }" />
        <div *ngIf="submitted && f.insPolicyAmount.errors" class="invalid-feedback">
            <div *ngIf="f.insPolicyAmount.errors">Policy Amount is required </div>
        </div>
    </div>
    <div class="form-group col">
        <label for="insPolicyGStAmount">Policy GST Amount</label>
        <input type="number" formControlName="insPolicyGStAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insPolicyGStAmount.errors }" />
        <div *ngIf="submitted && f.insPolicyGStAmount.errors" class="invalid-feedback">
            <div *ngIf="f.insPolicyGStAmount.errors">Policy GST Amount is required</div>
        </div>
    </div>
    <div class="form-group col">
        <label for="insPolicyTotalAmount">Policy Total Amount</label>
        <input type="number" formControlName="insPolicyTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insPolicyTotalAmount.errors }" />
        <div *ngIf="submitted && f.insPolicyTotalAmount.errors" class="invalid-feedback">
            <div *ngIf="f.insPolicyTotalAmount.errors">Policy Total Amount is required and should be in range of 0 to 40000</div>
        </div>
    </div>
    </div>
    
    <nav aria-label="breadcrumb" >
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page" >
                <p class="fa fa-book" style="margin:5px"></p>
            <p class="h5" style="margin-left: 5px;"> Plan Details</p></li>
        </ol>
      </nav>
      <div class="form-row">

        <div class="form-group col">
            <label for="planId">Plan</label>
            <select class="form-select" formControlName="planId" [ngClass]="{ 'is-invalid': submitted && f.planId.errors }" >
                <option *ngFor="let plan of plans " [ngValue]="plan.planId">
                  {{ plan.planId }}
                </option>
              </select>
            <div *ngIf="submitted && f.planId.errors" class="invalid-feedback">
                <div *ngIf="f.planId.errors.required">Plan is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="showFinance">
            <label for="financeCompanyName">Finance Company Name</label>
            <input type="text" formControlName="financeCompanyName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.financeCompanyName.errors }" />
            <div class="suggestions" >
                <div  *ngIf="f.financeCompanyName.value != selectedFinance">
                <option (click)="financeSelected(fin)"  *ngFor="let fin of selectedFinanceCompanies" >{{fin}}</option>
            </div>
            </div>
            <div *ngIf="submitted && f.financeCompanyName.errors" class="invalid-feedback">
                <div *ngIf="f.financeCompanyName.errors.required">Model Name is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="showFinance">
            <label for="financeCoverAmount">Finance Cover Amount</label>
            <input type="number" formControlName="financeCoverAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.financeCoverAmount.errors }" />
            <div *ngIf="submitted && f.financeCoverAmount.errors" class="invalid-feedback">
                <div *ngIf="f.financeCoverAmount.errors">Finance Cover amount is required and cant be more than cover limit of {{selectedPlan.planCPACoverAmount}}</div>
            </div>
       </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planAmount">Plan Amount</label>
            <input type="number" formControlName="planAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planAmount.errors }" />
        </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planGSTAmount">GST Amount</label>
            <input type="number" formControlName="planGSTAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planGSTAmount.errors }" />
        </div>
        <div class="form-group col">
            <label for="planTotalAmount">Total Amount</label>
            <input type="number" formControlName="planTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planTotalAmount.errors }" />
            <div *ngIf="submitted && f.planTotalAmount.errors" class="invalid-feedback">
                <div *ngIf="f.planTotalAmount.errors">Approved amount range is from {{minAmount}} to {{maxAmount}}</div>
            </div>
        </div>
    </div>

    <div class="form-row">

        <div class="form-group col">
            <label for="startDate">Start Date</label>
            <div class="input-group">
                <input class="form-control" formControlName="startDate"  placeholder="DD/MM/YYYY" [minDate]="startDateModel" ngbDatepicker #d1="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">Start Date is required</div>
            </div>
            <!-- <label for="startDate">Start Date</label>
            <input type="text" formControlName="startDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" /> -->
        </div>
        <div class="form-group col">
            <label for="endDate">End Date</label>
            <input type="text" formControlName="endDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
        </div>
        <div class="form-group col">
            <label for="issueDate">Issue Date</label>
            <input type="text" formControlName="issueDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.issueDate.errors }" />
        </div>
        <div class="form-group col" *ngIf="!(user.specialRoleAccess && user.specialRoleAccess.includes('CannotViewDealerBalance'))">
            <label for="balance">Balance</label>
            <input type="text" formControlName="balance" class="form-control" [ngClass]="{ 'is-invalid': isBalanceLow }" />
            <div *ngIf="isBalanceLow">Balance is running low. Please add money before proceeding</div>
        </div>

    </div>
    <div *ngIf="!(selectedPlan && selectedPlan.printCpaPolicy && selectedPlan.printCpaPolicy==  'No')">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
            <li class="breadcrumb-item active" aria-current="page" >
                <i class="fa fa-user" style="margin: 5px; "></i>
            <p class="h5" style="margin-left: 5px;" *ngIf = !isCorporate> Nominee Details</p>
            <p class="h5" style="margin-left: 5px;" *ngIf = isCorporate> Contact Person Details</p></li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
            <label for="nomineeName" *ngIf = !isCorporate>Nominee Name</label>
            <label for="nomineeName" *ngIf = isCorporate>Name</label>
            <input type="text" formControlName="nomineeName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nomineeName.errors }" />
            <div *ngIf="submitted && f.nomineeName.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeName.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="nomineeGender" *ngIf = !isCorporate>Nominee Gender</label>
            <label for="nomineeGender" *ngIf = isCorporate>Gender</label>
            <select class="form-select" formControlName="nomineeGender" [ngClass]="{ 'is-invalid': submitted && f.nomineeGender.errors }" >
                <option *ngFor="let nomineeGender of genders " [ngValue]="nomineeGender">
                  {{ nomineeGender }}
                </option>
              </select>
            <div *ngIf="submitted && f.nomineeGender.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeGender.errors.required">Gender is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf = !isCorporate>
            <label for="nomineeRelationship">Nominee Relationship</label>
            <select class="form-select" formControlName="nomineeRelationship" [ngClass]="{ 'is-invalid': submitted && f.nomineeRelationship.errors }" >
                <option *ngFor="let nomineeRelationship of relationships " [ngValue]="nomineeRelationship">
                  {{ nomineeRelationship }}
                </option>
              </select>
            <div *ngIf="submitted && f.nomineeRelationship.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeRelationship.errors.required">Nominee Relationship is required</div>
            </div>
        </div>
        <div class="form-group col" >
            <label for="nomineeAge" *ngIf = !isCorporate>Nominee Age</label>
            <label for="nomineeAge" *ngIf = isCorporate> Age</label>
                <input type="number" formControlName="nomineeAge" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nomineeAge.errors }" />
              <div *ngIf="submitted && f.nomineeAge.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeAge.errors.required"> Age is required</div>
                <div *ngIf="f.nomineeAge.errors.max">Maximum age allowed is 100</div>
                <div *ngIf="f.nomineeAge.errors.min">Minimum age allowed is 1</div>
            </div>
        </div>
        <div class="form-group col" *ngIf = isCorporate>
            <label for="employeeContactNumber">Contact Number</label>
            <input type="number" formControlName="employeeContactNumber" class="form-control" maxlength="10" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
            <div *ngIf="submitted && f.employeeContactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.employeeContactNumber.errors.required">Contact Number is required</div>
                <div *ngIf="f.employeeContactNumber.errors.minlength">Contact Number must be at least 6 characters</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="dealer.dealerCreatePackage">
    <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
        <li class="breadcrumb-item active" aria-current="page" >
            <i class="fa fa-user" style="margin: 5px; "></i>
        <p class="h5" style="margin-left: 5px;" > Package Details</p>
    </ol>
  </nav>
</div>
<div class="form-row" *ngIf="dealer.dealerCreatePackage">
    <div class="form-group col">
    <label for="packageAmount">Package Amount(Policy + Plan Amount)</label>
    <input type="number" formControlName="packageAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packageAmount.errors }" />
</div>
<div class="form-group col">
    <label for="packageGstAmount">Package GST Amount(Policy + Plan GST Amount)</label>
    <input type="number" formControlName="packageGstAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packageGstAmount.errors }" />
</div>
<div class="form-group col">
    <label for="packageTotalAmount">Total Package Amount</label>
    <input type="number" formControlName="packageTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packageTotalAmount.errors }" />
</div>
</div>
    <div *ngIf="certificateCreated">
        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb" style="background-color: rgb(217, 154, 236);">
                <li class="breadcrumb-item active" aria-current="page" >
                <i class="fa fa-book" style="margin-right: 10px; "></i>
                <p class="h5"> Policy Details</p>
                <p class="h5" style="text-align: right;" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanSeePolicyBalance')">{{"Policy Balance: " + dealer.dealerPolicyBalance}}</p>
            </li>
            </ol>
          </nav>
        <div class="form-row" *ngIf="certificateCreated">
    
        <div class="form-group col">
            <label for="certificateNumber">Certificate</label>
            <input type="text" formControlName="certificateNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.certificateNumber.errors }" disabled/>
        </div>
        <div class="form-group col">
            <label for="policyNumber">Policy Number</label>
            <input type="text" formControlName="policyNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.policyNumber.errors }" disabled/>
        </div>
    </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading || isBalanceLow" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button><a>&nbsp;&nbsp;</a>
        <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
            New Form
        </button>
        <a>&nbsp;&nbsp;</a>
        <button *ngIf="certificateCreated" class="btn btn-success mr-1" type="GenerateCertificate" (click)="onPdfClick()">
            Generate Certificate
        </button>
        <a routerLink="/issueCertificate" class="btn btn-link">Cancel</a>
    </div>
</form>
