<div class="container">
    <div class="row">
        <div class="col">
            <h4 >Endorse Certificate</h4>
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
        <div class="col">
            <div class="row">
            <h4 style= "position: relative;">Balance: &nbsp; </h4>
            <h4 style= "position: relative; background-color:blanchedalmond;" >{{dealer.dealerBalance}}</h4>
        </div>
        </div>
    </div>
</div>
<br>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page">
        <i class="fa fa-car" style="margin-right: 10px; "></i>
        <p class="h5"> Vehicle Details</p></li>
    </ol>
  </nav>
<form [formGroup]="certficateForm" >
    <div class="form-row">
        <div class="form-group col">
            <label for="vehicleMake">Make</label>
            <select class="form-select" formControlName="vehicleMake" [ngClass]="{ 'is-invalid': (submitted && f.vehicleMake.errors) || (f.vehicleMake.value != certificate.vehicleMake) }" >
                <option *ngFor="let vehicleMake of makeModel" [ngValue]="vehicleMake.make">
                  {{ vehicleMake.make }}
                </option>
              </select>
              <div *ngIf="submitted && f.vehicleMake.errors" class="invalid-feedback">
                <div *ngIf="f.vehicleMake.errors.required">Make needs to be selected</div>
            </div>
            <div *ngIf="f.vehicleMake.value != certificate.vehicleMake" class="invalid-feedback">
                <div *ngIf="f.vehicleMake.value != certificate.vehicleMake">Old Value was {{certificate.vehicleMake}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="model">Model</label>
            <select class="form-select" formControlName="model" [ngClass]="{ 'is-invalid':(submitted && f.model.errors) || (f.model.value != certificate.model) }">
                <option *ngFor="let model of models" [ngValue]="model">
                  {{ model }}
                </option>
              </select>
            <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
                <div *ngIf="f.model.errors.required">Model is required</div>
            </div>
            <div *ngIf="f.model.value != certificate.model" class="invalid-feedback">
                <div *ngIf="f.model.value != certificate.model">Old Value was {{certificate.model}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="type">Type</label>
            <select class="form-select" formControlName="type" [ngClass]="{ 'is-invalid': (submitted && f.type.errors) || (f.type.value != certificate.type) }" >
                <option *ngFor="let type of types " [ngValue]="type">
                  {{ type }}
                </option>
              </select>
            <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">Type is required</div>
            </div>
            <div *ngIf="f.type.value != certificate.type" class="invalid-feedback">
                <div *ngIf="f.type.value != certificate.type">Old Value was {{certificate.type}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="variant">Variant</label>
            <input type="text" formControlName="variant" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.variant.errors) || (f.variant.value != certificate.variant)}" />
            <div *ngIf="f.variant.value != certificate.variant" class="invalid-feedback">
                <div *ngIf="f.variant.value != certificate.variant">Old Value was {{certificate.variant}}</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="vehicleYear">Manufacturing Year</label>
            <select class="form-select" formControlName="vehicleYear" [ngClass]="{ 'is-invalid': (submitted && f.vehicleYear.errors) || (f.vehicleYear.value != certificate.vehicleYear) }" >
                <option *ngFor="let vehicleYear of years " [ngValue]="vehicleYear">
                  {{ vehicleYear }}
                </option>
              </select>
            <div *ngIf="submitted && f.vehicleYear.errors" class="invalid-feedback">
                <div *ngIf="f.vehicleYear.errors.required">Manufacturing year is required</div>
            </div>
            <div *ngIf="f.vehicleYear.value != certificate.vehicleYear" class="invalid-feedback">
                <div *ngIf="f.vehicleYear.value != certificate.vehicleYear">Old Value was {{certificate.vehicleYear}}</div>
            </div>
        </div>
            <div class="form-group col">
            <label for="registrationNumber">Registration Number</label>
            <input type="text" formControlName="registrationNumber" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.registrationNumber.errors) || (f.registrationNumber.value != certificate.registrationNumber) }" />
            <div *ngIf="submitted && f.registrationNumber.errors" class="invalid-feedback">
                <div *ngIf="f.registrationNumber.errors.required">Registration Number is required</div>
            </div>
            <div *ngIf="f.registrationNumber.value != certificate.registrationNumber" class="invalid-feedback">
                <div *ngIf="f.registrationNumber.value != certificate.registrationNumber">Old Value of Registraion Number was {{certificate.registrationNumber}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="chasisNumber">Chasis Number</label>
            <input type="text" formControlName="chasisNumber" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.chasisNumber.errors) || (f.chasisNumber.value != certificate.chasisNumber) }" />
            <div *ngIf="submitted && f.chasisNumber.errors" class="invalid-feedback">
                <div *ngIf="f.chasisNumber.errors.required">Chasis Number is required</div>
            </div>
            <div *ngIf="f.chasisNumber.value != certificate.chasisNumber" class="invalid-feedback">
                <div *ngIf="f.chasisNumber.value != certificate.chasisNumber">Old Value was {{certificate.chasisNumber}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="engineNumber">Engine Number</label>
            <input type="text" formControlName="engineNumber" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.engineNumber.errors) || (f.engineNumber.value != certificate.engineNumber) }" />
            <div *ngIf="submitted && f.engineNumber.errors" class="invalid-feedback">
                <div *ngIf="f.engineNumber.errors.required">Engine Number is required</div>
            </div>
        <div *ngIf="f.engineNumber.value != certificate.engineNumber" class="invalid-feedback">
            <div *ngIf="f.engineNumber.value != certificate.engineNumber">Old Value was {{certificate.engineNumber}}</div>
        </div>
    </div>
</div>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item active" aria-current="page">
            <i class="fa fa-user" style="margin-right: 10px; "></i>
            <p class="h5" *ngIf = !isCorporate> Customer Details</p>
            <p class="h5" *ngIf = isCorporate> Corporate Details</p></li>
        </ol>
      </nav>
    <div class="form-row">
        <div class="form-group col">
            <label for="clientType">Client Type</label>
            <select class="form-select" formControlName="clientType" [ngClass]="{ 'is-invalid': (submitted && f.clientType.errors) || (f.clientType.value != certificate.clientType) }" >
                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType">
                  {{ clientType }}
                </option>
              </select>
              <div *ngIf="submitted && f.clientType.errors" class="invalid-feedback">
                <div *ngIf="f.clientType.errors.required">Client Type needs to be selected</div>
            </div>
            <div *ngIf="f.clientType.value != certificate.clientType" class="invalid-feedback">
                <div *ngIf="f.clientType.value != certificate.clientType">Old Value was {{certificate.clientType}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="firstName" *ngIf = !isCorporate>First Name</label>
            <label for="firstName" *ngIf = isCorporate>Organisation Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.firstName.errors) || (f.firstName.value != certificate.firstName) }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">Name is required</div>
            </div>
            <div *ngIf="f.firstName.value != certificate.firstName" class="invalid-feedback">
                <div *ngIf="f.firstName.value != certificate.firstName">Old Value was {{certificate.firstName}}</div>
            </div>
        </div>
        <!-- <div class="form-group col">
            <label for="middleName">Middle Name</label>
            <input type="text" formControlName="middleName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.middleName.errors }" />
            <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
                <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
            </div>
        </div> -->
        <div class="form-group col" *ngIf = !isCorporate>
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.lastName.errors) || (f.lastName.value != certificate.lastName) }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        <div *ngIf="f.lastName.value != certificate.lastName" class="invalid-feedback">
            <div *ngIf="f.lastName.value != certificate.lastName">Old Value was {{certificate.lastName}}</div>
        </div>
    </div>
</div>
    <div class="form-row">
        <div class="form-group col">
            <label for="email" *ngIf = !isCorporate>Email</label>
            <label for="email" *ngIf = isCorporate>Organisation Email</label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.email.errors) || (f.email.value != certificate.email) }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email id is invalid</div>
            </div>
            <div *ngIf="f.email.value != certificate.email" class="invalid-feedback">
                <div *ngIf="f.email.value != certificate.email">Old Value was {{certificate.email}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="contactNumber">
                Contact Number
            </label>
            <input type="number" formControlName="contactNumber" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.contactNumber.errors) || (f.contactNumber.value != certificate.contactNumber) }" />
            <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.contactNumber.errors.required">Contact Number is required</div>
                <div *ngIf="f.contactNumber.errors.minlength">Contact Number must be at least 6 characters</div>
            </div>
            <div *ngIf="f.contactNumber.value != certificate.contactNumber" class="invalid-feedback">
                <div *ngIf="f.contactNumber.value != certificate.contactNumber">Old Value was {{certificate.contactNumber}}</div>
            </div>
        </div>
    </div>
    <div class="form-row" *ngIf = !isCorporate>
        <div class="form-group col">
            <label for="gender">Gender</label>
            <select class="form-select" formControlName="gender" [ngClass]="{ 'is-invalid': (submitted && f.gender.errors) || (f.gender.value != certificate.gender) }" >
                <option *ngFor="let gender of genders " [ngValue]="gender">
                  {{ gender }}
                </option>
              </select>
            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
            </div>
            <div *ngIf="f.gender.value != certificate.gender" class="invalid-feedback">
                <div *ngIf="f.gender.value != certificate.gender">Old Value was {{certificate.gender}}</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('canSeeAge')">
            <label for="ageOrDob">Age or Date of Birth</label>
            <div class="row">
                <div class="col">
                    <input type="radio" formControlName="ageOrDob" [value]="ageOrDobValue[1]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Age</span>
                </div>
                <div class="col">
                    <input type="radio" formControlName="ageOrDob"  [value]="ageOrDobValue[0]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Date of Birth</span>
                </div>
                <div *ngIf="f.ageOrDob.value != certificate.ageOrDob" class="invalid-feedback">
                    <div *ngIf="f.ageOrDob.value != certificate.ageOrDob">Old Value was {{certificate.ageOrDob}}</div>
                </div>
            </div>            
        </div>

        <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[1]">
            <label for="age">Age</label>
                <input type="number" formControlName="age" class="form-control" maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.age.errors }" />
                <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
                    <div *ngIf="f.age.errors.required"> Age is required</div>
                    <div *ngIf="f.age.errors.max">Maximum age allowed is 80</div>
                    <div *ngIf="f.age.errors.min">Minimum age allowed is 15</div>
                </div>
                <div *ngIf="f.age.value != certificate.age" class="invalid-feedback">
                    <div *ngIf="f.age.value != certificate.age">Old Value was {{certificate.age}}</div>
                </div>
        </div>
        <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[0]">
            <label for="dob">Date of Birth</label>
                <input class="form-control" formControlName="dob"  [minDate]="minDobDateModel" [maxDate]="maxDobDateModel" placeholder="DD/MM/YYYY" ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
                <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                    <div >Date of birth is required and age should be between 15 to 80 years</div>
                </div>
                <div *ngIf="customerDob != certificate.dob" class="invalid-feedback">
                    <div *ngIf="customerDob != certificate.dob">Old Value was {{certificate.dob}}</div>
                </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="address" *ngIf = !isCorporate>Address</label>
            <label for="address" *ngIf = isCorporate>Organisation Address</label>
            <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.address.errors) || (f.address.value != certificate.address) }" />
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required">Address is required</div>
            </div>
            <div *ngIf="f.address.value != certificate.address" class="invalid-feedback">
                <div *ngIf="f.address.value != certificate.address">Old Value was {{certificate.address}}</div>
            </div>
        </div>
            <div class="form-group col">
                <label for="state">State</label>
                <select class="form-select" formControlName="state" [ngClass]="{ 'is-invalid': (submitted && f.state.errors) || (f.state.value != certificate.state) }" >
                    <option *ngFor="let state of stateCity" [ngValue]="state.state">
                      {{ state.state }}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                    <div *ngIf="f.state.errors.required">State needs to be selected</div>
                </div>
                <div *ngIf="f.state.value != certificate.state" class="invalid-feedback">
                    <div *ngIf="f.state.value != certificate.state">Old Value was {{certificate.state}}</div>
                </div>
                </div>
            <div class="form-group col">
                <label for="city">City</label>
                <select class="form-select" formControlName="city" [ngClass]="{ 'is-invalid': (submitted && f.city.errors) || (f.city.value != certificate.city) }">
                    <option *ngFor="let city of cities" [ngValue]="city">
                      {{ city }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
                <div *ngIf="f.city.value != certificate.city" class="invalid-feedback">
                    <div *ngIf="f.city.value != certificate.city">Old Value was {{certificate.city}}</div>
                </div>
                </div>    
    </div>
    <nav aria-label="breadcrumb" *ngIf="certificate.nomineeName != 'NA'">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item active" aria-current="page">
            <i class="fa fa-user" style="margin-right: 10px; "></i>
            <p class="h5" *ngIf = !isCorporate> Nominee Details</p>
            <p class="h5" *ngIf = isCorporate> Contact Person Details</p></li>
        </ol>
      </nav>
      <div class="form-row" *ngIf="certificate.nomineeName != 'NA'">
        <div class="form-group col">
            <label for="nomineeName" *ngIf = !isCorporate>Nominee Name</label>
            <label for="nomineeName" *ngIf = isCorporate>Name</label>
            <input type="text" formControlName="nomineeName" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.nomineeName.errors) || (f.nomineeName.value != certificate.nomineeName) }" />
            <div *ngIf="submitted && f.nomineeName.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeName.errors.required">Name is required</div>
            </div>
            <div *ngIf="f.nomineeName.value != certificate.nomineeName" class="invalid-feedback">
                <div *ngIf="f.nomineeName.value != certificate.nomineeName">Old Value was {{certificate.nomineeName}}</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="nomineeGender" *ngIf = !isCorporate>Nominee Gender</label>
            <label for="nomineeGender" *ngIf = isCorporate>Gender</label>
            <select class="form-select" formControlName="nomineeGender" [ngClass]="{ 'is-invalid': (submitted && f.nomineeGender.errors) || (f.nomineeGender.value != certificate.nomineeGender) }" >
                <option *ngFor="let nomineeGender of genders " [ngValue]="nomineeGender">
                  {{ nomineeGender }}
                </option>
              </select>
            <div *ngIf="submitted && f.nomineeGender.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeGender.errors.required">Gender is required</div>
            </div>
            <div *ngIf="f.nomineeGender.value != certificate.nomineeGender" class="invalid-feedback">
                <div *ngIf="f.nomineeGender.value != certificate.nomineeGender">Old Value was {{certificate.nomineeGender}}</div>
            </div>
        </div>
        <div class="form-group col" *ngIf = !isCorporate>
            <label for="nomineeRelationship">Nominee Relationship</label>
            <select class="form-select" formControlName="nomineeRelationship" [ngClass]="{ 'is-invalid': (submitted && f.nomineeRelationship.errors) || (f.nomineeRelationship.value != certificate.nomineeRelationship) }" >
                <option *ngFor="let nomineeRelationship of relationships " [ngValue]="nomineeRelationship">
                  {{ nomineeRelationship }}
                </option>
              </select>
            <div *ngIf="submitted && f.nomineeRelationship.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeRelationship.errors.required">Nominee Relationship is required</div>
            </div>
            <div *ngIf="f.nomineeRelationship.value != certificate.nomineeRelationship" class="invalid-feedback">
                <div *ngIf="f.nomineeRelationship.value != certificate.nomineeRelationship">Old Value was {{certificate.nomineeRelationship}}</div>
            </div>
        </div>
        <div class="form-group col" >
            <label for="nomineeAge" *ngIf = !isCorporate>Nominee Age</label>
            <label for="nomineeAge" *ngIf = isCorporate> Age</label>
                <input type="number" formControlName="nomineeAge" class="form-control" [ngClass]="{ 'is-invalid': (submitted && f.nomineeAge.errors) || (f.nomineeAge.value != certificate.nomineeAge) }" />
              <div *ngIf="submitted && f.nomineeAge.errors" class="invalid-feedback">
                <div *ngIf="f.nomineeAge.errors.required"> Age is required</div>
                <div *ngIf="f.nomineeAge.errors.max">Maximum age allowed is 100</div>
                <div *ngIf="f.nomineeAge.errors.min">Minimum age allowed is 1</div>
            </div>
            <div *ngIf="f.nomineeAge.value != certificate.nomineeAge" class="invalid-feedback">
                <div *ngIf="f.nomineeAge.value != certificate.nomineeAge">Old Value was {{certificate.nomineeAge}}</div>
            </div>
        </div>
        <div class="form-group col" *ngIf = isCorporate>
            <label for="employeeContactNumber">Contact Number</label>
            <input type="number" formControlName="employeeContactNumber" class="form-control" maxlength="10" [ngClass]="{ 'is-invalid': (submitted && f.employeeContactNumber.errors) || (f.employeeContactNumber.value != certificate.employeeContactNumber) }" />
            <div *ngIf="submitted && f.employeeContactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.employeeContactNumber.errors.required">Contact Number is required</div>
                <div *ngIf="f.employeeContactNumber.errors.minlength">Contact Number must be at least 6 characters</div>
            </div>
            <div *ngIf="f.employeeContactNumber.value != certificate.employeeContactNumber" class="invalid-feedback">
                <div *ngIf="f.employeeContactNumber.value != certificate.employeeContactNumber">Old Value was {{certificate.employeeContactNumber}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="certificateCreated">
        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb" >
              <li class="breadcrumb-item active" aria-current="page" >
                <i class="fa fa-book" style="margin-right: 10px; "></i>
                <p class="h5"> Endorsement Details</p></li>
            </ol>
          </nav>
        <div class="form-row" *ngIf="certificateCreated">
    
        <div class="form-group col">
            <label for="endorsementReason">Endorsement Details</label>
            <input type="text" formControlName="endorsementReason" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endorsementReason.errors }" disabled/>
        </div>
        <div class="form-group col">
            <label for="endorsementCount">Endorsement Count</label>
            <input type="text" formControlName="endorsementCount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endorsementCount.errors }" disabled/>
        </div>
    </div>
    </div>

    <div class="form-group">
        <button [disabled]="loading || isBalanceLow" class="btn btn-primary" (click)="onEndorseClick()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Endorse
        </button><a>&nbsp;&nbsp;</a>
        <button *ngIf="certificateCreated" class="btn btn-success mr-1" type="GenerateCertificate" (click)="onPdfClick()">
            Generate Certificate
        </button>
    </div>
</form>
